export const AssessmentTypes = [
  { _id: 'assistive', name: 'Assistive Technology' },
  { _id: 'combination', name: 'Combination' },
  { _id: 'functional', name: 'Functional Capacity' },
  { _id: 'neuro', name: 'Neuro-Psychological' },
  { _id: 'psycho', name: 'Psycho-Educational' },
  { _id: 'psychological', name: 'Psychological' },
  { _id: 'vocational', name: 'Vocational' },
  { _id: 'worksite', name: 'Worksite' },
];
