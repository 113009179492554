<div
  *ngFor="let file of files; last as isLast; let i = index"
  class="file-container"
  [ngClass]="{ 'not-last-row': !isLast }"
>
  <div class="metadata-container" [class]="file.errors ? 'file-error' : ''">
    <div class="document-type">
      <span class="uploaded-icon material-icons" aria-hidden="true" *ngIf="!file.errors && file.completed">
        check_box
      </span>
      <span class="document-type-text">{{ file.documentType || categoryDisplay }}</span>
    </div>
    <div class="file-name" [attr.data-cy]="file.name + '-' + i.toString()">{{ file.name }}</div>
    <div
      class="preview-button"
      [class]="file.errors ? 'error' : ''"
      (click)="onView(file.key)"
      iesIconButton
      data-cy="preview"
      [attr.aria-label]="ariaLabelForPreviewIcon"
      *ngIf="!file.errors && file.completed"
    >
      View file
    </div>
    <div class="preview-button material-icons hidden" *ngIf="!(!file.errors && file.completed)">visibility</div>
    <div class="spacer"></div>
    <div
      *ngIf="!hideRemoveButton"
      (click)="onRemoveFile(file)"
      class="remove-button"
      data-cy="remove"
      aria-label="Remove File"
      iesIconButton
    >
      <span class="remove-icon material-icons" aria-hidden="true"> delete_outline </span>
      <span class="remove-text">Remove</span>
    </div>
  </div>
  <div class="error-text" *ngIf="file?.errors?.upload">
    <ng-container>Document Upload Failed</ng-container>
  </div>
</div>
