import { AlbertaAddress, BasePerson, PhoneNumber } from '@common/entities';
import { Expose, Type } from 'class-transformer';
import { IsDefined, IsEmail, IsNotEmpty, ValidateIf, ValidateNested } from 'class-validator';
import { Document } from '@common/entities';
import { RequiredDocumentsIn, IsFullAddress } from '@common/validation';
import { HaveValidNumbers } from '../custom-validation-functions/have-valid-number';
import { FiSecondParent } from './second-parent.entity';

export class FiParent extends BasePerson {
  @Expose()
  id?: string;

  @Expose()
  preferredName?: string;

  @Expose()
  @IsDefined()
  isEnglishFirstLanguage?: boolean;

  @Expose()
  @ValidateIf((o) => o.isEnglishFirstLanguage == false)
  @IsNotEmpty()
  primaryLanguage?: string;

  @Expose()
  @ValidateIf((o) => o.isEnglishFirstLanguage == false)
  @IsDefined()
  requiresInterpreter?: boolean;

  @Expose()
  @IsDefined()
  haveOtherCommunicationNeed?: boolean;

  @Expose()
  @ValidateIf((o) => o.haveOtherCommunicationNeed)
  @IsNotEmpty()
  otherCommunicationNeed?: string;

  @Expose()
  @Type(() => PhoneNumber)
  @ValidateNested()
  @HaveValidNumbers()
  @IsNotEmpty()
  phoneNumbers?: Array<PhoneNumber>;

  @Expose()
  @IsDefined()
  @IsEmail()
  email?: string;

  @Expose()
  @IsDefined()
  hasSameAddressAsChild: boolean;

  @Expose()
  @Type(() => AlbertaAddress)
  @ValidateIf((o) => o.hasSameAddressAsChild == false)
  @ValidateNested()
  @IsFullAddress()
  residentialAddress?: AlbertaAddress;

  @Expose()
  @IsDefined()
  relationToChild?: string;

  @Expose()
  @Type(() => Document)
  @ValidateIf((o) => o.relationToChild === 'other')
  @ValidateNested({ each: true })
  @RequiredDocumentsIn('identification')
  documents?: Document[];

  @Expose()
  learnAboutFscdPhysician: boolean;

  @Expose()
  learnAboutFscdHospital: boolean;

  @Expose()
  learnAboutFscdAgency: boolean;

  @Expose()
  learnAboutFscdEarlyInterventionProgram: boolean;

  @Expose()
  learnAboutFscdEasterSeals: boolean;

  @Expose()
  learnAboutFscdHealthUnit: boolean;

  @Expose()
  learnAboutFscdSchool: boolean;

  @Expose()
  learnAboutFscdChildrensServices: boolean;

  @Expose()
  learnAboutFscdOther: boolean;

  @Expose()
  @ValidateIf((o) => o.learnAboutFscdOther)
  @IsNotEmpty()
  learnAboutFscdOtherDetail?: string;

  @Expose()
  hasSecondParent: boolean;

  @Expose()
  @Type(() => FiSecondParent)
  @ValidateIf((o) => o.hasSecondParent == true)
  @ValidateNested()
  secondParent?: FiSecondParent;
}
