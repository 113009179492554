import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Document } from '@common/entities';
import { Subject } from 'rxjs';

import { FileService } from '../shared/services/file/file.service';

@Component({
  selector: 'common-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DocumentListComponent implements OnDestroy {
  @Input() parentId: string;
  @Input() files: Document[];
  @Input() categoryDisplay: string;
  @Input() tooltipMessage: string;
  @Input() tooltipPosition: string;
  @Input() ariaLabelForPreviewIcon: string;
  @Input() hideRemoveButton = false;
  @Output() removeFile = new EventEmitter<Document>();
  private destroy$ = new Subject<void>();
  constructor(private fileService: FileService) {}
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  onRemoveFile(document: Document) {
    document.isDeleted = true;
    this.removeFile.emit(document);
  }

  onView(documentKey: string) {
    this.fileService.getDocument(documentKey, this.parentId).subscribe((result) => {
      window.open(result['URL']);
    });
  }
}
