import { Municipality } from '../entities/municipality.entity';

export const Municipalities: Municipality[] = [
  { _id: 'Abee', name: 'Abee', region: 'Northeast' },
  { _id: 'Abilene', name: 'Abilene', region: 'Northeast' },
  { _id: 'Acadia Valley', name: 'Acadia Valley', region: 'South' },
  { _id: 'Acheson', name: 'Acheson', region: 'Edmonton' },
  { _id: 'Acme', name: 'Acme', region: 'Central' },
  { _id: 'Aden', name: 'Aden', region: 'South' },
  { _id: 'Aetna', name: 'Aetna', region: 'South' },
  { _id: 'Agatha', name: 'Agatha', region: 'South' },
  { _id: 'Aggie', name: 'Aggie', region: 'Northwest' },
  { _id: 'Airdrie', name: 'Airdrie', region: 'Calgary' },
  { _id: 'Akenside', name: 'Akenside', region: 'Edmonton' },
  { _id: 'Alberta Beach', name: 'Alberta Beach', region: 'Northeast' },
  { _id: 'Albright', name: 'Albright', region: 'Northwest' },
  { _id: 'Alcomdale', name: 'Alcomdale', region: 'Edmonton' },
  { _id: 'Alcurve', name: 'Alcurve', region: 'Central' },
  { _id: 'Alder Flats', name: 'Alder Flats', region: 'Central' },
  { _id: 'Alderson', name: 'Alderson', region: 'South' },
  { _id: 'Aldersyde', name: 'Aldersyde', region: 'Calgary' },
  { _id: 'Alexo', name: 'Alexo', region: 'Central' },
  { _id: 'Alhambra', name: 'Alhambra', region: 'Central' },
  { _id: 'Alix', name: 'Alix', region: 'Central' },
  { _id: 'Alliance', name: 'Alliance', region: 'Central' },
  { _id: 'Allingham', name: 'Allingham', region: 'Central' },
  { _id: 'Alness', name: 'Alness', region: 'Central' },
  { _id: 'Alpen Siding', name: 'Alpen Siding', region: 'Northeast' },
  { _id: 'Alsike', name: 'Alsike', region: 'Central' },
  { _id: 'Altario', name: 'Altario', region: 'Central' },
  { _id: 'Amber Valley', name: 'Amber Valley', region: 'Northeast' },
  { _id: 'Amesbury', name: 'Amesbury', region: 'Northeast' },
  { _id: 'Amisk', name: 'Amisk', region: 'Central' },
  { _id: 'Ancona', name: 'Ancona', region: 'Central' },
  { _id: 'Andrew', name: 'Andrew', region: 'Northeast' },
  { _id: 'Ankerton', name: 'Ankerton', region: 'Central' },
  { _id: 'Ansell', name: 'Ansell', region: 'Northwest' },
  { _id: 'Anselmo', name: 'Anselmo', region: 'Northwest' },
  { _id: 'Antler Lake', name: 'Antler Lake', region: 'Edmonton' },
  { _id: 'Anzac', name: 'Anzac', region: 'Northeast' },
  { _id: 'Arcadia', name: 'Arcadia', region: 'Northwest' },
  { _id: 'Ardenode', name: 'Ardenode', region: 'Calgary' },
  { _id: 'Ardley', name: 'Ardley', region: 'Central' },
  { _id: 'Ardmore', name: 'Ardmore', region: 'Northeast' },
  { _id: 'Ardrossan', name: 'Ardrossan', region: 'Edmonton' },
  { _id: 'Argentia Beach', name: 'Argentia Beach', region: 'Central' },
  { _id: 'Armena', name: 'Armena', region: 'Central' },
  { _id: 'Arneson', name: 'Arneson', region: 'South' },
  { _id: 'Arrowwood', name: 'Arrowwood', region: 'Calgary' },
  { _id: 'Arvilla', name: 'Arvilla', region: 'Northeast' },
  { _id: 'Ashmont', name: 'Ashmont', region: 'Northeast' },
  { _id: 'Aspen Beach', name: 'Aspen Beach', region: 'Central' },
  { _id: 'Assineau', name: 'Assineau', region: 'Northwest' },
  { _id: 'Assumption', name: 'Assumption', region: 'Northwest' },
  { _id: 'Athabasca', name: 'Athabasca', region: 'Northeast' },
  { _id: 'Atikameg', name: 'Atikameg', region: 'Northwest' },
  { _id: 'Atlee', name: 'Atlee', region: 'South' },
  { _id: 'Atmore', name: 'Atmore', region: 'Northeast' },
  { _id: 'Bad Heart', name: 'Bad Heart', region: 'Northwest' },
  { _id: 'Bain', name: 'Bain', region: 'South' },
  { _id: 'Baintree', name: 'Baintree', region: 'Calgary' },
  { _id: 'Balzac', name: 'Balzac', region: 'Calgary' },
  { _id: 'Banff', name: 'Banff', region: 'Calgary' },
  { _id: 'Bantry', name: 'Bantry', region: 'South' },
  { _id: 'Baptiste River', name: 'Baptiste River', region: 'Central' },
  { _id: 'Bardo', name: 'Bardo', region: 'Central' },
  { _id: 'Bargrave', name: 'Bargrave', region: 'Central' },
  { _id: 'Barich', name: 'Barich', region: 'Northeast' },
  { _id: 'Barlee Junction', name: 'Barlee Junction', region: 'Central' },
  { _id: 'Barnegat', name: 'Barnegat', region: 'Northeast' },
  { _id: 'Barnwell', name: 'Barnwell', region: 'South' },
  { _id: 'Barons', name: 'Barons', region: 'South' },
  { _id: 'Barrhead', name: 'Barrhead', region: 'Northeast' },
  { _id: 'Bashaw', name: 'Bashaw', region: 'Central' },
  { _id: 'Bassano', name: 'Bassano', region: 'South' },
  { _id: 'Battle Lake', name: 'Battle Lake', region: 'Central' },
  { _id: 'Bawlf', name: 'Bawlf', region: 'Central' },
  { _id: 'Bay Tree', name: 'Bay Tree', region: 'Northwest' },
  { _id: 'Beach Corner', name: 'Beach Corner', region: 'Edmonton' },
  { _id: 'Beacon Corner', name: 'Beacon Corner', region: 'Northeast' },
  { _id: 'Bear Canyon', name: 'Bear Canyon', region: 'Northwest' },
  { _id: 'Bearberry', name: 'Bearberry', region: 'Central' },
  { _id: 'Beaumont', name: 'Beaumont', region: 'Edmonton' },
  { _id: 'Beauvallon', name: 'Beauvallon', region: 'Northeast' },
  { _id: 'Beaver Crossing', name: 'Beaver Crossing', region: 'Northeast' },
  { _id: 'Beaver Lake', name: 'Beaver Lake', region: 'Northeast' },
  { _id: 'Beaver Mines', name: 'Beaver Mines', region: 'South' },
  { _id: 'Beaver River', name: 'Beaver River', region: 'Northeast' },
  { _id: 'Beaverdam', name: 'Beaverdam', region: 'Northeast' },
  { _id: 'Beaverhill', name: 'Beaverhill', region: 'Central' },
  { _id: 'Beaverlodge', name: 'Beaverlodge', region: 'Northwest' },
  { _id: 'Beazer', name: 'Beazer', region: 'South' },
  { _id: 'Behan', name: 'Behan', region: 'Northeast' },
  { _id: 'Beiseker', name: 'Beiseker', region: 'Calgary' },
  { _id: 'Bellevue', name: 'Bellevue', region: 'South' },
  { _id: 'Bellis', name: 'Bellis', region: 'Northeast' },
  { _id: 'Belloy', name: 'Belloy', region: 'Northwest' },
  { _id: 'Benalto', name: 'Benalto', region: 'Central' },
  { _id: 'Benchlands', name: 'Benchlands', region: 'Calgary' },
  { _id: 'Bentley', name: 'Bentley', region: 'Central' },
  { _id: 'Benton Station', name: 'Benton Station', region: 'South' },
  { _id: 'Bergen', name: 'Bergen', region: 'Central' },
  { _id: 'Berry Creek', name: 'Berry Creek', region: 'Central' },
  { _id: 'Berrymoor', name: 'Berrymoor', region: 'Central' },
  { _id: 'Berwyn', name: 'Berwyn', region: 'Northwest' },
  { _id: 'Betula Beach', name: 'Betula Beach', region: 'Edmonton' },
  { _id: 'Beynon', name: 'Beynon', region: 'Central' },
  { _id: 'Bezanson', name: 'Bezanson', region: 'Northwest' },
  { _id: 'Bickerdike', name: 'Bickerdike', region: 'Northwest' },
  { _id: 'Big Coulee', name: 'Big Coulee', region: 'Northeast' },
  { _id: 'Big Eddy', name: 'Big Eddy', region: 'Northwest' },
  { _id: 'Big Stone', name: 'Big Stone', region: 'Central' },
  { _id: 'Big Valley', name: 'Big Valley', region: 'Central' },
  { _id: 'Bilby', name: 'Bilby', region: 'Edmonton' },
  { _id: 'Bindloss', name: 'Bindloss', region: 'South' },
  { _id: 'Bingley', name: 'Bingley', region: 'Central' },
  { _id: 'Birch Cove', name: 'Birch Cove', region: 'Northeast' },
  { _id: 'Bircham', name: 'Bircham', region: 'Central' },
  { _id: 'Birchcliff', name: 'Birchcliff', region: 'Central' },
  { _id: 'Bison Lake', name: 'Bison Lake', region: 'Northwest' },
  { _id: 'Bittern Lake', name: 'Bittern Lake', region: 'Central' },
  { _id: 'Black Diamond', name: 'Black Diamond', region: 'Calgary' },
  { _id: 'Blackfalds', name: 'Blackfalds', region: 'Central' },
  { _id: 'Blackfoot', name: 'Blackfoot', region: 'Central' },
  { _id: 'Blackie', name: 'Blackie', region: 'Calgary' },
  { _id: 'Blairmore', name: 'Blairmore', region: 'South' },
  { _id: 'Bloomsbury', name: 'Bloomsbury', region: 'Northeast' },
  { _id: 'Blue Ridge', name: 'Blue Ridge', region: 'Northwest' },
  { _id: 'Blueberry Mountain', name: 'Blueberry Mountain', region: 'Northwest' },
  { _id: 'Bluesky', name: 'Bluesky', region: 'Northwest' },
  { _id: 'Bluffton', name: 'Bluffton', region: 'Central' },
  { _id: 'Bodo', name: 'Bodo', region: 'Central' },
  { _id: 'Bon Accord', name: 'Bon Accord', region: 'Edmonton' },
  { _id: 'Bonanza', name: 'Bonanza', region: 'Northwest' },
  { _id: 'Bonar', name: 'Bonar', region: 'Central' },
  { _id: 'Bondiss', name: 'Bondiss', region: 'Northeast' },
  { _id: 'Bonnyville', name: 'Bonnyville', region: 'Northeast' },
  { _id: 'Bonnyville Beach', name: 'Bonnyville Beach', region: 'Northeast' },
  { _id: 'Borradaile', name: 'Borradaile', region: 'Central' },
  { _id: 'Boscombe', name: 'Boscombe', region: 'Northeast' },
  { _id: 'Botha', name: 'Botha', region: 'Central' },
  { _id: 'Bottrel', name: 'Bottrel', region: 'Calgary' },
  { _id: 'Bow City', name: 'Bow City', region: 'South' },
  { _id: 'Bow Island', name: 'Bow Island', region: 'South' },
  { _id: 'Bowden', name: 'Bowden', region: 'Central' },
  { _id: 'Bowell', name: 'Bowell', region: 'South' },
  { _id: 'Boyle', name: 'Boyle', region: 'Northeast' },
  { _id: 'Boyne Lake', name: 'Boyne Lake', region: 'Northeast' },
  { _id: 'Braeburn', name: 'Braeburn', region: 'Northwest' },
  { _id: 'Bragg Creek', name: 'Bragg Creek', region: 'Calgary' },
  { _id: 'Brainard', name: 'Brainard', region: 'Northwest' },
  { _id: 'Brant', name: 'Brant', region: 'Calgary' },
  { _id: 'Brazeau', name: 'Brazeau', region: 'Central' },
  { _id: 'Brazeau Reservoir', name: 'Brazeau Reservoir', region: 'Central' },
  { _id: 'Breton', name: 'Breton', region: 'Central' },
  { _id: 'Breynat', name: 'Breynat', region: 'Northeast' },
  { _id: 'Briereville', name: 'Briereville', region: 'Northeast' },
  { _id: 'Brightview', name: 'Brightview', region: 'Central' },
  { _id: 'Brocket', name: 'Brocket', region: 'South' },
  { _id: 'Brooks', name: 'Brooks', region: 'South' },
  { _id: 'Brosseau', name: 'Brosseau', region: 'Northeast' },
  { _id: 'Brownfield', name: 'Brownfield', region: 'Central' },
  { _id: 'Brownvale', name: 'Brownvale', region: 'Northwest' },
  { _id: 'Broxburn', name: 'Broxburn', region: 'South' },
  { _id: 'Bruce', name: 'Bruce', region: 'Central' },
  { _id: 'Bruderheim', name: 'Bruderheim', region: 'Northeast' },
  { _id: 'Brule', name: 'Brule', region: 'Northwest' },
  { _id: 'Buck Creek', name: 'Buck Creek', region: 'Central' },
  { _id: 'Buck Lake', name: 'Buck Lake', region: 'Central' },
  { _id: 'Buffalo', name: 'Buffalo', region: 'South' },
  { _id: 'Buffalo Head Prairie', name: 'Buffalo Head Prairie', region: 'Northwest' },
  { _id: 'Buffalo Lake', name: 'Buffalo Lake', region: 'Northeast' },
  { _id: 'Buffalo Lake Metis Settlement', name: 'Buffalo Lake Metis Settlement', region: 'Northeast' },
  { _id: 'Buford', name: 'Buford', region: 'Edmonton' },
  { _id: 'Bullpound', name: 'Bullpound', region: 'South' },
  { _id: 'Bulls Head', name: 'Bulls Head', region: 'South' },
  { _id: 'Bulwark', name: 'Bulwark', region: 'Central' },
  { _id: 'Buoyant', name: 'Buoyant', region: 'Central' },
  { _id: 'Burdett', name: 'Burdett', region: 'South' },
  { _id: 'Burmis', name: 'Burmis', region: 'South' },
  { _id: 'Burnstick Lake', name: 'Burnstick Lake', region: 'Central' },
  { _id: 'Busby', name: 'Busby', region: 'Northeast' },
  { _id: 'Byemoor', name: 'Byemoor', region: 'Central' },
  { _id: 'Cadogan', name: 'Cadogan', region: 'Central' },
  { _id: 'Cadomin', name: 'Cadomin', region: 'Northwest' },
  { _id: 'Cadotte Lake', name: 'Cadotte Lake', region: 'Northwest' },
  { _id: 'Cairns', name: 'Cairns', region: 'Central' },
  { _id: 'Calahoo', name: 'Calahoo', region: 'Edmonton' },
  { _id: 'Calais', name: 'Calais', region: 'Northwest' },
  { _id: 'Calgary', name: 'Calgary', region: 'Calgary' },
  { _id: 'Calling Lake', name: 'Calling Lake', region: 'Northeast' },
  { _id: 'Calmar', name: 'Calmar', region: 'Edmonton' },
  { _id: 'Calthorpe', name: 'Calthorpe', region: 'Central' },
  { _id: 'Cambria', name: 'Cambria', region: 'South' },
  { _id: 'Camp Creek', name: 'Camp Creek', region: 'Northeast' },
  { _id: 'Campsie', name: 'Campsie', region: 'Northeast' },
  { _id: 'Camrose', name: 'Camrose', region: 'Central' },
  { _id: 'Canmore', name: 'Canmore', region: 'Calgary' },
  { _id: 'Canyon Creek', name: 'Canyon Creek', region: 'Northwest' },
  { _id: 'Cappon', name: 'Cappon', region: 'South' },
  { _id: 'Carbon', name: 'Carbon', region: 'Central' },
  { _id: 'Carbondale', name: 'Carbondale', region: 'Edmonton' },
  { _id: 'Carcajou', name: 'Carcajou', region: 'Northwest' },
  { _id: 'Cardiff', name: 'Cardiff', region: 'Edmonton' },
  { _id: 'Cardston', name: 'Cardston', region: 'South' },
  { _id: 'Carlos', name: 'Carlos', region: 'Central' },
  { _id: 'Carmangay', name: 'Carmangay', region: 'Calgary' },
  { _id: 'Carnwood', name: 'Carnwood', region: 'Central' },
  { _id: 'Caroline', name: 'Caroline', region: 'Central' },
  { _id: 'Carolside', name: 'Carolside', region: 'South' },
  { _id: 'Carrot Creek', name: 'Carrot Creek', region: 'Northwest' },
  { _id: 'Carseland', name: 'Carseland', region: 'Calgary' },
  { _id: 'Carstairs', name: 'Carstairs', region: 'Central' },
  { _id: 'Carvel', name: 'Carvel', region: 'Edmonton' },
  { _id: 'Carway', name: 'Carway', region: 'South' },
  { _id: 'Caslan', name: 'Caslan', region: 'Northeast' },
  { _id: 'Cassils', name: 'Cassils', region: 'South' },
  { _id: 'Castle Island', name: 'Castle Island', region: 'Northeast' },
  { _id: 'Castor', name: 'Castor', region: 'Central' },
  { _id: 'Cavendish', name: 'Cavendish', region: 'South' },
  { _id: 'Cayley', name: 'Cayley', region: 'Calgary' },
  { _id: 'Cecil', name: 'Cecil', region: 'South' },
  { _id: 'Cereal', name: 'Cereal', region: 'South' },
  { _id: 'Cessford', name: 'Cessford', region: 'South' },
  { _id: 'Champion', name: 'Champion', region: 'Calgary' },
  { _id: 'Chancellor', name: 'Chancellor', region: 'Calgary' },
  { _id: 'Chard', name: 'Chard', region: 'Northeast' },
  { _id: 'Charron', name: 'Charron', region: 'Northeast' },
  { _id: 'Chateh', name: 'Chateh', region: 'Northwest' },
  { _id: 'Chauvin', name: 'Chauvin', region: 'Central' },
  { _id: 'Cheadle', name: 'Cheadle', region: 'Calgary' },
  { _id: 'Chedderville', name: 'Chedderville', region: 'Central' },
  { _id: 'Cheecham', name: 'Cheecham', region: 'Northeast' },
  { _id: 'Cherhill', name: 'Cherhill', region: 'Northeast' },
  { _id: 'Cherry Grove', name: 'Cherry Grove', region: 'Northeast' },
  { _id: 'Cherry Point', name: 'Cherry Point', region: 'Northwest' },
  { _id: 'Chestermere', name: 'Chestermere', region: 'Calgary' },
  { _id: 'Chigwell', name: 'Chigwell', region: 'Central' },
  { _id: 'Chin', name: 'Chin', region: 'South' },
  { _id: 'Chinook', name: 'Chinook', region: 'South' },
  { _id: 'Chinook Valley', name: 'Chinook Valley', region: 'Northwest' },
  { _id: 'Chip Lake', name: 'Chip Lake', region: 'Northwest' },
  { _id: 'Chipewyan Lake', name: 'Chipewyan Lake', region: 'Northwest' },
  { _id: 'Chipman', name: 'Chipman', region: 'Northeast' },
  { _id: 'Chisholm Mills', name: 'Chisholm Mills', region: 'Northwest' },
  { _id: 'Clairmont', name: 'Clairmont', region: 'Northwest' },
  { _id: 'Clandonald', name: 'Clandonald', region: 'Central' },
  { _id: 'Claresholm', name: 'Claresholm', region: 'Calgary' },
  { _id: 'Clarkson Valley', name: 'Clarkson Valley', region: 'Northwest' },
  { _id: 'Claysmore', name: 'Claysmore', region: 'Central' },
  { _id: 'Clear Hills', name: 'Clear Hills', region: 'Northwest' },
  { _id: 'Clear Prairie', name: 'Clear Prairie', region: 'Northwest' },
  { _id: 'Cleardale', name: 'Cleardale', region: 'Northwest' },
  { _id: 'Clive', name: 'Clive', region: 'Central' },
  { _id: 'Cluny', name: 'Cluny', region: 'Calgary' },
  { _id: 'Clyde', name: 'Clyde', region: 'Northeast' },
  { _id: 'Coaldale', name: 'Coaldale', region: 'South' },
  { _id: 'Coalhurst', name: 'Coalhurst', region: 'South' },
  { _id: 'Coalspur', name: 'Coalspur', region: 'Northwest' },
  { _id: 'Cochrane', name: 'Cochrane', region: 'Calgary' },
  { _id: 'Cochrane Lake', name: 'Cochrane Lake', region: 'Calgary' },
  { _id: 'Codesa', name: 'Codesa', region: 'Northwest' },
  { _id: 'Coghill', name: 'Coghill', region: 'Central' },
  { _id: 'Cold Lake', name: 'Cold Lake', region: 'Northeast' },
  { _id: 'Coleman', name: 'Coleman', region: 'South' },
  { _id: 'Colinton', name: 'Colinton', region: 'Northeast' },
  { _id: 'Collingwood Cove', name: 'Collingwood Cove', region: 'Edmonton' },
  { _id: 'Compeer', name: 'Compeer', region: 'Central' },
  { _id: 'Comrey', name: 'Comrey', region: 'South' },
  { _id: 'Condor', name: 'Condor', region: 'Central' },
  { _id: 'Conklin', name: 'Conklin', region: 'Northeast' },
  { _id: 'Conrad', name: 'Conrad', region: 'South' },
  { _id: 'Conrich', name: 'Conrich', region: 'Calgary' },
  { _id: 'Consort', name: 'Consort', region: 'Central' },
  { _id: 'Cooking Lake', name: 'Cooking Lake', region: 'Edmonton' },
  { _id: 'Cordel', name: 'Cordel', region: 'Central' },
  { _id: 'Coronado', name: 'Coronado', region: 'Northeast' },
  { _id: 'Coronation', name: 'Coronation', region: 'Central' },
  { _id: 'Cosway', name: 'Cosway', region: 'Central' },
  { _id: 'Countess', name: 'Countess', region: 'South' },
  { _id: 'Cousins', name: 'Cousins', region: 'South' },
  { _id: 'Coutts', name: 'Coutts', region: 'South' },
  { _id: 'Cowley', name: 'Cowley', region: 'South' },
  { _id: 'Craddock', name: 'Craddock', region: 'South' },
  { _id: 'Craigmyle', name: 'Craigmyle', region: 'Central' },
  { _id: 'Crammond', name: 'Crammond', region: 'Central' },
  { _id: 'Cranford', name: 'Cranford', region: 'South' },
  { _id: 'Cremona', name: 'Cremona', region: 'Central' },
  { _id: 'Cressday', name: 'Cressday', region: 'South' },
  { _id: 'Crimson Lake', name: 'Crimson Lake', region: 'Central' },
  { _id: 'Crooked Creek', name: 'Crooked Creek', region: 'Northwest' },
  { _id: 'Crossfield', name: 'Crossfield', region: 'Calgary' },
  { _id: 'Crowsnest Pass', name: 'Crowsnest Pass', region: 'South' },
  { _id: 'Crystal Springs', name: 'Crystal Springs', region: 'Central' },
  { _id: 'Culp', name: 'Culp', region: 'Northwest' },
  { _id: 'Cynthia', name: 'Cynthia', region: 'Central' },
  { _id: 'Czar', name: 'Czar', region: 'Central' },
  { _id: 'Dalehurst', name: 'Dalehurst', region: 'Northwest' },
  { _id: 'Dalemead', name: 'Dalemead', region: 'Calgary' },
  { _id: 'Dalroy', name: 'Dalroy', region: 'Calgary' },
  { _id: 'Dalum', name: 'Dalum', region: 'Calgary' },
  { _id: 'Dapp', name: 'Dapp', region: 'Northeast' },
  { _id: 'Darwell', name: 'Darwell', region: 'Northeast' },
  { _id: 'Daysland', name: 'Daysland', region: 'Central' },
  { _id: 'De Winton', name: 'De Winton', region: 'Calgary' },
  { _id: "Dead Man's Flats", name: "Dead Man's Flats", region: 'Calgary' },
  { _id: 'Deadwood', name: 'Deadwood', region: 'Northwest' },
  { _id: 'Debolt', name: 'Debolt', region: 'Northwest' },
  { _id: 'Decoigne', name: 'Decoigne', region: 'Northwest' },
  { _id: 'Decrene', name: 'Decrene', region: 'Northwest' },
  { _id: 'Deep Creek', name: 'Deep Creek', region: 'Northeast' },
  { _id: 'Del Bonita', name: 'Del Bonita', region: 'South' },
  { _id: 'Delacour', name: 'Delacour', region: 'Calgary' },
  { _id: 'Delburne', name: 'Delburne', region: 'Central' },
  { _id: 'Delia', name: 'Delia', region: 'Central' },
  { _id: 'Delph', name: 'Delph', region: 'Northeast' },
  { _id: 'Demay', name: 'Demay', region: 'Central' },
  { _id: 'Demmitt', name: 'Demmitt', region: 'Northwest' },
  { _id: 'Denhart', name: 'Denhart', region: 'South' },
  { _id: 'Dennis', name: 'Dennis', region: 'South' },
  { _id: 'Denwood', name: 'Denwood', region: 'Central' },
  { _id: 'Derwent', name: 'Derwent', region: 'Northeast' },
  { _id: 'Desert Blume', name: 'Desert Blume', region: 'South' },
  { _id: 'Desmarais', name: 'Desmarais', region: 'Northwest' },
  { _id: 'Devenish', name: 'Devenish', region: 'Northwest' },
  { _id: 'Deville', name: 'Deville', region: 'Edmonton' },
  { _id: 'Devon', name: 'Devon', region: 'Edmonton' },
  { _id: 'Dewberry', name: 'Dewberry', region: 'Central' },
  { _id: 'Diamond City', name: 'Diamond City', region: 'South' },
  { _id: 'Diamond Valley', name: 'Diamond Valley', region: 'Calgary' },
  { _id: 'Dickson', name: 'Dickson', region: 'Central' },
  { _id: 'Didsbury', name: 'Didsbury', region: 'Central' },
  { _id: 'Dimsdale', name: 'Dimsdale', region: 'Northwest' },
  { _id: 'Dinant', name: 'Dinant', region: 'Central' },
  { _id: 'Diss', name: 'Diss', region: 'Northwest' },
  { _id: 'Dixonville', name: 'Dixonville', region: 'Northwest' },
  { _id: 'Doan', name: 'Doan', region: 'Central' },
  { _id: 'Dodds', name: 'Dodds', region: 'Central' },
  { _id: 'Dog Pound', name: 'Dog Pound', region: 'Central' },
  { _id: 'Donalda', name: 'Donalda', region: 'Central' },
  { _id: 'Donatville', name: 'Donatville', region: 'Northeast' },
  { _id: 'Donnelly', name: 'Donnelly', region: 'Northwest' },
  { _id: 'Dorenlee', name: 'Dorenlee', region: 'Central' },
  { _id: 'Dorothy', name: 'Dorothy', region: 'South' },
  { _id: 'Dovercourt', name: 'Dovercourt', region: 'Central' },
  { _id: 'Dowling', name: 'Dowling', region: 'Central' },
  { _id: 'Draper', name: 'Draper', region: 'Northwest' },
  { _id: 'Drayton Valley', name: 'Drayton Valley', region: 'Central' },
  { _id: 'Driftpile', name: 'Driftpile', region: 'Northwest' },
  { _id: 'Drinnan', name: 'Drinnan', region: 'Northwest' },
  { _id: 'Drumheller', name: 'Drumheller', region: 'Central' },
  { _id: 'Drywood', name: 'Drywood', region: 'South' },
  { _id: 'Duchess', name: 'Duchess', region: 'South' },
  { _id: 'Duffield', name: 'Duffield', region: 'Edmonton' },
  { _id: 'Duhamel', name: 'Duhamel', region: 'Central' },
  { _id: 'Dunmore', name: 'Dunmore', region: 'South' },
  { _id: 'Dunn', name: 'Dunn', region: 'Central' },
  { _id: 'Dunphy', name: 'Dunphy', region: 'Central' },
  { _id: 'Dunstable', name: 'Dunstable', region: 'Northeast' },
  { _id: 'Duthil', name: 'Duthil', region: 'Calgary' },
  { _id: 'Duvernay', name: 'Duvernay', region: 'Northeast' },
  { _id: 'Eagle Butte', name: 'Eagle Butte', region: 'South' },
  { _id: 'Eagle Hill', name: 'Eagle Hill', region: 'Central' },
  { _id: 'Eaglesham', name: 'Eaglesham', region: 'Northwest' },
  { _id: 'East Prairie Metis Settlement', name: 'East Prairie Metis Settlement', region: 'Northwest' },
  { _id: 'Eckville', name: 'Eckville', region: 'Central' },
  { _id: 'Edberg', name: 'Edberg', region: 'Central' },
  { _id: 'Eden Valley', name: 'Eden Valley', region: 'Calgary' },
  { _id: 'Edgerton', name: 'Edgerton', region: 'Central' },
  { _id: 'Edmonton', name: 'Edmonton', region: 'Edmonton' },
  { _id: 'Edmonton Beach', name: 'Edmonton Beach', region: 'Edmonton' },
  { _id: 'Edson', name: 'Edson', region: 'Northwest' },
  { _id: 'Egg Lake', name: 'Egg Lake', region: 'Northwest' },
  { _id: 'Egremont', name: 'Egremont', region: 'Northeast' },
  { _id: 'Eladesor', name: 'Eladesor', region: 'Central' },
  { _id: 'Elizabeth Metis Settlement', name: 'Elizabeth Metis Settlement', region: 'Northeast' },
  { _id: 'Elk Island', name: 'Elk Island', region: 'Edmonton' },
  { _id: 'Elk Point', name: 'Elk Point', region: 'Northeast' },
  { _id: 'Elkwater', name: 'Elkwater', region: 'South' },
  { _id: 'Ellscott', name: 'Ellscott', region: 'Northeast' },
  { _id: 'Elmworth', name: 'Elmworth', region: 'Northwest' },
  { _id: 'Elnora', name: 'Elnora', region: 'Central' },
  { _id: 'Elspeth', name: 'Elspeth', region: 'Central' },
  { _id: 'Embarras', name: 'Embarras', region: 'Northwest' },
  { _id: 'Embarras Portage', name: 'Embarras Portage', region: 'Northwest' },
  { _id: 'Empress', name: 'Empress', region: 'South' },
  { _id: 'Enchant', name: 'Enchant', region: 'South' },
  { _id: 'Endiang', name: 'Endiang', region: 'Central' },
  { _id: 'Enilda', name: 'Enilda', region: 'Northwest' },
  { _id: 'Enoch', name: 'Enoch', region: 'Edmonton' },
  { _id: 'Ensign', name: 'Ensign', region: 'Calgary' },
  { _id: 'Entice', name: 'Entice', region: 'Central' },
  { _id: 'Entrance', name: 'Entrance', region: 'Northwest' },
  { _id: 'Entwistle', name: 'Entwistle', region: 'Edmonton' },
  { _id: 'Equity', name: 'Equity', region: 'Central' },
  { _id: 'Erith', name: 'Erith', region: 'Northwest' },
  { _id: 'Erskine', name: 'Erskine', region: 'Central' },
  { _id: 'Ervick', name: 'Ervick', region: 'Central' },
  { _id: 'Esther', name: 'Esther', region: 'South' },
  { _id: 'Etzikom', name: 'Etzikom', region: 'South' },
  { _id: 'Eureka River', name: 'Eureka River', region: 'Northwest' },
  { _id: 'Evansburg', name: 'Evansburg', region: 'Edmonton' },
  { _id: 'Evarts', name: 'Evarts', region: 'Central' },
  { _id: 'Excel', name: 'Excel', region: 'Central' },
  { _id: 'Exshaw', name: 'Exshaw', region: 'Calgary' },
  { _id: 'Fabyan', name: 'Fabyan', region: 'Central' },
  { _id: 'Fairview', name: 'Fairview', region: 'Northwest' },
  { _id: 'Falher', name: 'Falher', region: 'Northwest' },
  { _id: 'Fallis', name: 'Fallis', region: 'Edmonton' },
  { _id: 'Falun', name: 'Falun', region: 'Central' },
  { _id: 'Farrant', name: 'Farrant', region: 'Central' },
  { _id: 'Faust', name: 'Faust', region: 'Northwest' },
  { _id: 'Fawcett', name: 'Fawcett', region: 'Northeast' },
  { _id: 'Federal', name: 'Federal', region: 'Central' },
  { _id: 'Fenn', name: 'Fenn', region: 'Central' },
  { _id: 'Ferintosh', name: 'Ferintosh', region: 'Central' },
  { _id: 'Ferrier', name: 'Ferrier', region: 'Central' },
  { _id: 'Fidler', name: 'Fidler', region: 'Northwest' },
  { _id: 'Fincastle', name: 'Fincastle', region: 'South' },
  { _id: 'Finnegan', name: 'Finnegan', region: 'South' },
  { _id: 'Fishing Lake Metis Settlement', name: 'Fishing Lake Metis Settlement', region: 'Northeast' },
  { _id: 'Fitzallen', name: 'Fitzallen', region: 'Northeast' },
  { _id: 'Fitzgerald', name: 'Fitzgerald', region: 'Northeast' },
  { _id: 'Flat Lake', name: 'Flat Lake', region: 'Northeast' },
  { _id: 'Flatbush', name: 'Flatbush', region: 'Northeast' },
  { _id: 'Fleet', name: 'Fleet', region: 'Central' },
  { _id: 'Foisy', name: 'Foisy', region: 'Northeast' },
  { _id: 'Foothills', name: 'Foothills', region: 'Northwest' },
  { _id: 'Foremost', name: 'Foremost', region: 'South' },
  { _id: 'Forestburg', name: 'Forestburg', region: 'Central' },
  { _id: 'Fork Lake', name: 'Fork Lake', region: 'Northeast' },
  { _id: 'Forshee', name: 'Forshee', region: 'Central' },
  { _id: 'Fort Assiniboine', name: 'Fort Assiniboine', region: 'Northwest' },
  { _id: 'Fort Chipewyan', name: 'Fort Chipewyan', region: 'Northeast' },
  { _id: 'Fort Fitzgerald', name: 'Fort Fitzgerald', region: 'Northeast' },
  { _id: 'Fort Kent', name: 'Fort Kent', region: 'Northeast' },
  { _id: 'Fort Lake', name: 'Fort Lake', region: 'Northeast' },
  { _id: 'Fort MacLeod', name: 'Fort MacLeod', region: 'South' },
  { _id: 'Fort McKay', name: 'Fort McKay', region: 'Northeast' },
  { _id: 'Fort McMurray', name: 'Fort McMurray', region: 'Northeast' },
  { _id: 'Fort Saskatchewan', name: 'Fort Saskatchewan', region: 'Edmonton' },
  { _id: 'Fort Vermilion', name: 'Fort Vermilion', region: 'Northwest' },
  { _id: 'Fox Creek', name: 'Fox Creek', region: 'Northwest' },
  { _id: 'Fox Lake', name: 'Fox Lake', region: 'Northwest' },
  { _id: 'Frains', name: 'Frains', region: 'Northeast' },
  { _id: 'Franchere', name: 'Franchere', region: 'Northeast' },
  { _id: 'Frank', name: 'Frank', region: 'South' },
  { _id: 'Freedom', name: 'Freedom', region: 'Northeast' },
  { _id: 'Friedenstal', name: 'Friedenstal', region: 'Northwest' },
  { _id: 'Frog Lake', name: 'Frog Lake', region: 'Northeast' },
  { _id: 'Gadsby', name: 'Gadsby', region: 'Central' },
  { _id: 'Gage', name: 'Gage', region: 'Northwest' },
  { _id: 'Gainford', name: 'Gainford', region: 'Edmonton' },
  { _id: 'Galahad', name: 'Galahad', region: 'Central' },
  { _id: 'Galloway', name: 'Galloway', region: 'Northwest' },
  { _id: 'Garden Plain', name: 'Garden Plain', region: 'Central' },
  { _id: 'Garden River', name: 'Garden River', region: 'Northwest' },
  { _id: 'Garth', name: 'Garth', region: 'Central' },
  { _id: 'Gartly', name: 'Gartly', region: 'Central' },
  { _id: 'Gatine', name: 'Gatine', region: 'Central' },
  { _id: 'Gayford', name: 'Gayford', region: 'Calgary' },
  { _id: 'Geikie', name: 'Geikie', region: 'Northwest' },
  { _id: 'Gem', name: 'Gem', region: 'South' },
  { _id: 'Genesee', name: 'Genesee', region: 'Edmonton' },
  { _id: 'Ghost Lake', name: 'Ghost Lake', region: 'Calgary' },
  { _id: 'Ghost Pine Creek', name: 'Ghost Pine Creek', region: 'Central' },
  { _id: 'Gibbons', name: 'Gibbons', region: 'Edmonton' },
  { _id: 'Gift Lake', name: 'Gift Lake', region: 'Northwest' },
  { _id: 'Gift Lake Metis Settlement', name: 'Gift Lake Metis Settlement', region: 'Northwest' },
  { _id: 'Gilby', name: 'Gilby', region: 'Central' },
  { _id: 'Girouxville', name: 'Girouxville', region: 'Northwest' },
  { _id: 'Gleichen', name: 'Gleichen', region: 'Calgary' },
  { _id: 'Glen Park', name: 'Glen Park', region: 'Central' },
  { _id: 'Glendon', name: 'Glendon', region: 'Northeast' },
  { _id: 'Glenevis', name: 'Glenevis', region: 'Northeast' },
  { _id: 'Glenford', name: 'Glenford', region: 'Edmonton' },
  { _id: 'Glenister', name: 'Glenister', region: 'Northeast' },
  { _id: 'Glenwood', name: 'Glenwood', region: 'South' },
  { _id: 'Golden Days', name: 'Golden Days', region: 'Central' },
  { _id: 'Goodfare', name: 'Goodfare', region: 'Northwest' },
  { _id: 'Goodfish Lake', name: 'Goodfish Lake', region: 'Northeast' },
  { _id: 'Goodridge', name: 'Goodridge', region: 'Northeast' },
  { _id: 'Goodwin', name: 'Goodwin', region: 'Northwest' },
  { _id: 'Goose Lake', name: 'Goose Lake', region: 'Northwest' },
  { _id: 'Gordondale', name: 'Gordondale', region: 'Northwest' },
  { _id: 'Grainger', name: 'Grainger', region: 'Central' },
  { _id: 'Granada', name: 'Granada', region: 'Northwest' },
  { _id: 'Grand Centre', name: 'Grand Centre', region: 'Northeast' },
  { _id: 'Grande Cache', name: 'Grande Cache', region: 'Northwest' },
  { _id: 'Grande Prairie', name: 'Grande Prairie', region: 'Northwest' },
  { _id: 'Grandview', name: 'Grandview', region: 'Central' },
  { _id: 'Grantham', name: 'Grantham', region: 'South' },
  { _id: 'Granum', name: 'Granum', region: 'South' },
  { _id: 'Grassland', name: 'Grassland', region: 'Northeast' },
  { _id: 'Grassy Lake', name: 'Grassy Lake', region: 'South' },
  { _id: 'Greencourt', name: 'Greencourt', region: 'Northwest' },
  { _id: 'Greenshields', name: 'Greenshields', region: 'Central' },
  { _id: 'Grimshaw', name: 'Grimshaw', region: 'Northwest' },
  { _id: 'Grosmont', name: 'Grosmont', region: 'Northeast' },
  { _id: 'Grouard', name: 'Grouard', region: 'Northwest' },
  { _id: 'Grovedale', name: 'Grovedale', region: 'Northwest' },
  { _id: 'Gull Lake', name: 'Gull Lake', region: 'Central' },
  { _id: 'Gundy', name: 'Gundy', region: 'Northwest' },
  { _id: 'Gunn', name: 'Gunn', region: 'Northeast' },
  { _id: 'Gurneyville', name: 'Gurneyville', region: 'Northeast' },
  { _id: 'Guy', name: 'Guy', region: 'Northwest' },
  { _id: 'Gwynne', name: 'Gwynne', region: 'Central' },
  { _id: 'Habay', name: 'Habay', region: 'Northwest' },
  { _id: 'Hackett', name: 'Hackett', region: 'Central' },
  { _id: 'Haddock', name: 'Haddock', region: 'Northwest' },
  { _id: 'Haight', name: 'Haight', region: 'Central' },
  { _id: 'Hairy Hill', name: 'Hairy Hill', region: 'Northeast' },
  { _id: 'Halach', name: 'Halach', region: 'Northeast' },
  { _id: 'Halcourt', name: 'Halcourt', region: 'Northwest' },
  { _id: 'Half Moon Bay', name: 'Half Moon Bay', region: 'Northwest' },
  { _id: 'Half Moon Lake', name: 'Half Moon Lake', region: 'Edmonton' },
  { _id: 'Halkirk', name: 'Halkirk', region: 'Central' },
  { _id: 'Halsbury', name: 'Halsbury', region: 'South' },
  { _id: 'Hamlet', name: 'Hamlet', region: 'Calgary' },
  { _id: 'Hamlin', name: 'Hamlin', region: 'Northeast' },
  { _id: 'Hanlon', name: 'Hanlon', region: 'Northeast' },
  { _id: 'Hanna', name: 'Hanna', region: 'Central' },
  { _id: 'Hardieville', name: 'Hardieville', region: 'South' },
  { _id: 'Hardisty', name: 'Hardisty', region: 'Central' },
  { _id: 'Hargwen', name: 'Hargwen', region: 'Northwest' },
  { _id: 'Harlech', name: 'Harlech', region: 'Central' },
  { _id: 'Harmattan', name: 'Harmattan', region: 'Central' },
  { _id: 'Harmon Valley', name: 'Harmon Valley', region: 'Northwest' },
  { _id: 'Hartleyville', name: 'Hartleyville', region: 'South' },
  { _id: 'Harvie Heights', name: 'Harvie Heights', region: 'Calgary' },
  { _id: 'Hastings Lake', name: 'Hastings Lake', region: 'Edmonton' },
  { _id: 'Hattonford', name: 'Hattonford', region: 'Northwest' },
  { _id: 'Hawkins', name: 'Hawkins', region: 'Central' },
  { _id: 'Hay Lakes', name: 'Hay Lakes', region: 'Central' },
  { _id: 'Haynes', name: 'Haynes', region: 'Central' },
  { _id: 'Hays', name: 'Hays', region: 'South' },
  { _id: 'Hayter', name: 'Hayter', region: 'Central' },
  { _id: 'Hazeldine', name: 'Hazeldine', region: 'Central' },
  { _id: 'Hazelmere', name: 'Hazelmere', region: 'Northwest' },
  { _id: 'Heart Lake First Nation', name: 'Heart Lake First Nation', region: 'Northeast' },
  { _id: 'Heart Valley', name: 'Heart Valley', region: 'Northwest' },
  { _id: 'Heatburg', name: 'Heatburg', region: 'Central' },
  { _id: 'Heath', name: 'Heath', region: 'Central' },
  { _id: 'Heatherdown', name: 'Heatherdown', region: 'Edmonton' },
  { _id: 'Heinsburg', name: 'Heinsburg', region: 'Northeast' },
  { _id: 'Heisler', name: 'Heisler', region: 'Central' },
  { _id: 'Heldar', name: 'Heldar', region: 'Northwest' },
  { _id: 'Helina', name: 'Helina', region: 'Northeast' },
  { _id: 'Hemaruka', name: 'Hemaruka', region: 'Central' },
  { _id: 'Henry House', name: 'Henry House', region: 'Northwest' },
  { _id: 'Heritage Pointe', name: 'Heritage Pointe', region: 'Calgary' },
  { _id: 'Herronton', name: 'Herronton', region: 'Calgary' },
  { _id: 'Hesketh', name: 'Hesketh', region: 'Central' },
  { _id: 'Hespero', name: 'Hespero', region: 'Central' },
  { _id: 'High Level', name: 'High Level', region: 'Northwest' },
  { _id: 'High Prairie', name: 'High Prairie', region: 'Northwest' },
  { _id: 'High River', name: 'High River', region: 'Calgary' },
  { _id: 'Highridge', name: 'Highridge', region: 'Northeast' },
  { _id: 'Highvale', name: 'Highvale', region: 'Edmonton' },
  { _id: 'Highway', name: 'Highway', region: 'Northwest' },
  { _id: 'Hilda', name: 'Hilda', region: 'South' },
  { _id: 'Hill Spring', name: 'Hill Spring', region: 'South' },
  { _id: 'Hillcrest', name: 'Hillcrest', region: 'South' },
  { _id: 'Hillcrest Mines', name: 'Hillcrest Mines', region: 'South' },
  { _id: 'Hilliard', name: 'Hilliard', region: 'Northeast' },
  { _id: 'Hillsdown', name: 'Hillsdown', region: 'Central' },
  { _id: 'Hines Creek', name: 'Hines Creek', region: 'Northwest' },
  { _id: 'Hinton', name: 'Hinton', region: 'Northwest' },
  { _id: 'Hoadley', name: 'Hoadley', region: 'Central' },
  { _id: 'Hobbema', name: 'Hobbema', region: 'Central' },
  { _id: 'Holden', name: 'Holden', region: 'Central' },
  { _id: 'Hollow Lake', name: 'Hollow Lake', region: 'Northeast' },
  { _id: 'Homestead', name: 'Homestead', region: 'Northwest' },
  { _id: 'Hondo', name: 'Hondo', region: 'Northwest' },
  { _id: 'Horseshoe Bay', name: 'Horseshoe Bay', region: 'Northeast' },
  { _id: 'Hoselaw', name: 'Hoselaw', region: 'Northeast' },
  { _id: 'Hotchkiss', name: 'Hotchkiss', region: 'Northwest' },
  { _id: 'Huallen', name: 'Huallen', region: 'Northwest' },
  { _id: 'Hughenden', name: 'Hughenden', region: 'Central' },
  { _id: 'Hussar', name: 'Hussar', region: 'Calgary' },
  { _id: 'Huxley', name: 'Huxley', region: 'Central' },
  { _id: 'Hylo', name: 'Hylo', region: 'Northeast' },
  { _id: 'Hythe', name: 'Hythe', region: 'Northwest' },
  { _id: 'Iddesleigh', name: 'Iddesleigh', region: 'South' },
  { _id: 'Imperial Mills', name: 'Imperial Mills', region: 'Northeast' },
  { _id: 'Indian Cabins', name: 'Indian Cabins', region: 'Northwest' },
  { _id: 'Indus', name: 'Indus', region: 'Calgary' },
  { _id: 'Inland', name: 'Inland', region: 'Central' },
  { _id: 'Innisfail', name: 'Innisfail', region: 'Central' },
  { _id: 'Innisfree', name: 'Innisfree', region: 'Northeast' },
  { _id: 'Irma', name: 'Irma', region: 'Central' },
  { _id: 'Iron River', name: 'Iron River', region: 'Northeast' },
  { _id: 'Iron Springs', name: 'Iron Springs', region: 'South' },
  { _id: 'Irricana', name: 'Irricana', region: 'Calgary' },
  { _id: 'Irvine', name: 'Irvine', region: 'South' },
  { _id: 'Island Lake', name: 'Island Lake', region: 'Northeast' },
  { _id: 'Island Lake South', name: 'Island Lake South', region: 'Northeast' },
  { _id: 'Islay', name: 'Islay', region: 'Central' },
  { _id: 'Ispas', name: 'Ispas', region: 'Northeast' },
  { _id: 'Itaska Beach', name: 'Itaska Beach', region: 'Edmonton' },
  { _id: 'Jackson', name: 'Jackson', region: 'Central' },
  { _id: 'James River Bridge', name: 'James River Bridge', region: 'Central' },
  { _id: 'Janet', name: 'Janet', region: 'Calgary' },
  { _id: 'Janvier', name: 'Janvier', region: 'Northeast' },
  { _id: 'Jarrow', name: 'Jarrow', region: 'Central' },
  { _id: 'Jarvie', name: 'Jarvie', region: 'Northeast' },
  { _id: 'Jarvis Bay', name: 'Jarvis Bay', region: 'Central' },
  { _id: 'Jasper', name: 'Jasper', region: 'Northwest' },
  { _id: 'Jean Cote', name: 'Jean Cote', region: 'Northwest' },
  { _id: 'Jefferson', name: 'Jefferson', region: 'South' },

  { _id: 'Jeffrey', name: 'Jeffrey', region: 'Northeast' },
  { _id: 'Jenner', name: 'Jenner', region: 'South' },
  { _id: 'Joffre', name: 'Joffre', region: 'Central' },
  { _id: "John d'Or Prairie", name: "John d'Or Prairie", region: 'Northwest' },
  { _id: 'Josephburg', name: 'Josephburg', region: 'Edmonton' },
  { _id: 'Joussard', name: 'Joussard', region: 'Northwest' },
  { _id: 'Judah', name: 'Judah', region: 'Northwest' },
  { _id: 'Judson', name: 'Judson', region: 'South' },
  { _id: 'Kaleland', name: 'Kaleland', region: 'Northeast' },
  { _id: 'Kananakis', name: 'Kananakis', region: 'Calgary' },
  { _id: 'Kapasiwin', name: 'Kapasiwin', region: 'Edmonton' },
  { _id: 'Kathleen', name: 'Kathleen', region: 'Northwest' },
  { _id: 'Kathryn', name: 'Kathryn', region: 'Calgary' },
  { _id: 'Kavanagh', name: 'Kavanagh', region: 'Edmonton' },
  { _id: 'Keephills', name: 'Keephills', region: 'Edmonton' },
  { _id: 'Keg River', name: 'Keg River', region: 'Northwest' },
  { _id: 'Kehewin', name: 'Kehewin', region: 'Northeast' },
  { _id: 'Kelsey', name: 'Kelsey', region: 'Central' },
  { _id: 'Kenzie', name: 'Kenzie', region: 'Northwest' },
  { _id: 'Keoma', name: 'Keoma', region: 'Calgary' },
  { _id: 'Kerensky', name: 'Kerensky', region: 'Northeast' },
  { _id: 'Kevisville', name: 'Kevisville', region: 'Central' },
  { _id: 'Kikino', name: 'Kikino', region: 'Northeast' },
  { _id: 'Kikino Metis Settlement', name: 'Kikino Metis Settlement', region: 'Northeast' },
  { _id: 'Killam', name: 'Killam', region: 'Central' },
  { _id: 'Kilsyth', name: 'Kilsyth', region: 'Northwest' },
  { _id: 'Kimball', name: 'Kimball', region: 'South' },
  { _id: 'Kingman', name: 'Kingman', region: 'Central' },
  { _id: 'Kinosis', name: 'Kinosis', region: 'Northeast' },
  { _id: 'Kinsella', name: 'Kinsella', region: 'Central' },
  { _id: 'Kinuso', name: 'Kinuso', region: 'Northwest' },
  { _id: 'Kipp', name: 'Kipp', region: 'South' },
  { _id: 'Kirkcaldy', name: 'Kirkcaldy', region: 'Calgary' },
  { _id: 'Kirkpatrick', name: 'Kirkpatrick', region: 'Central' },
  { _id: 'Kiron', name: 'Kiron', region: 'Central' },
  { _id: 'Kirriemuir', name: 'Kirriemuir', region: 'Central' },
  { _id: 'Kitscoty', name: 'Kitscoty', region: 'Central' },
  { _id: 'Knee Hill Valley', name: 'Knee Hill Valley', region: 'Central' },
  { _id: 'Ksituan', name: 'Ksituan', region: 'Northwest' },
  { _id: 'La Corey', name: 'La Corey', region: 'Northeast' },
  { _id: 'La Crete', name: 'La Crete', region: 'Northwest' },
  { _id: 'La Glace', name: 'La Glace', region: 'Northwest' },
  { _id: 'LaBuma', name: 'LaBuma', region: 'Central' },
  { _id: 'Lac Des Arcs', name: 'Lac Des Arcs', region: 'Calgary' },
  { _id: 'Lac La Biche', name: 'Lac La Biche', region: 'Northeast' },
  { _id: 'Lac La Nonne', name: 'Lac La Nonne', region: 'Northeast' },
  { _id: 'Lac Ste. Anne', name: 'Lac Ste. Anne', region: 'Northeast' },
  { _id: 'Lacombe', name: 'Lacombe', region: 'Central' },
  { _id: 'Lafond', name: 'Lafond', region: 'Northeast' },
  { _id: 'Lake Eliza', name: 'Lake Eliza', region: 'Northeast' },
  { _id: 'Lake Isle', name: 'Lake Isle', region: 'Northeast' },
  { _id: 'Lake Loiuse', name: 'Lake Louise', region: 'Calgary' },
  { _id: 'Lake Majeau', name: 'Lake Majeau', region: 'Northeast' },
  { _id: 'Lake Newell Resort', name: 'Lake Newell Resort', region: 'South' },
  { _id: 'Lakedell', name: 'Lakedell', region: 'Central' },
  { _id: 'Lakeview', name: 'Lakeview', region: 'Edmonton' },
  { _id: 'Lamerton', name: 'Lamerton', region: 'Central' },
  { _id: 'Lamont', name: 'Lamont', region: 'Northeast' },
  { _id: 'Lamoureux', name: 'Lamoureux', region: 'Edmonton' },
  { _id: 'Lanfine', name: 'Lanfine', region: 'South' },
  { _id: 'Langdon', name: 'Langdon', region: 'Calgary' },
  { _id: 'Larkspur', name: 'Larkspur', region: 'Northeast' },
  { _id: 'Lathom', name: 'Lathom', region: 'South' },
  { _id: 'Lavoy', name: 'Lavoy', region: 'Northeast' },
  { _id: 'Le Goff', name: 'Le Goff', region: 'Northeast' },
  { _id: 'Leahurst', name: 'Leahurst', region: 'Central' },
  { _id: 'Leaman', name: 'Leaman', region: 'Central' },
  { _id: 'Leapark', name: 'Leapark', region: 'Central' },
  { _id: 'Leavitt', name: 'Leavitt', region: 'South' },
  { _id: 'Leduc', name: 'Leduc', region: 'Edmonton' },
  { _id: 'Leedale', name: 'Leedale', region: 'Central' },
  { _id: 'Legal', name: 'Legal', region: 'Edmonton' },
  { _id: 'Legend', name: 'Legend', region: 'South' },
  { _id: 'Lehigh', name: 'Lehigh', region: 'Central' },
  { _id: 'Leicester', name: 'Leicester', region: 'Northwest' },
  { _id: 'Lenarthur', name: 'Lenarthur', region: 'Northeast' },
  { _id: 'Leo', name: 'Leo', region: 'Central' },
  { _id: 'Leslieville', name: 'Leslieville', region: 'Central' },
  { _id: 'Lessard', name: 'Lessard', region: 'Northeast' },
  { _id: 'Lethbridge', name: 'Lethbridge', region: 'South' },
  { _id: 'Leyland', name: 'Leyland', region: 'Northwest' },
  { _id: 'Linaria', name: 'Linaria', region: 'Northeast' },
  { _id: 'Lindale', name: 'Lindale', region: 'Edmonton' },
  { _id: 'Lindbergh', name: 'Lindbergh', region: 'Northeast' },
  { _id: 'Lindbrook', name: 'Lindbrook', region: 'Central' },
  { _id: 'Linden', name: 'Linden', region: 'Central' },
  { _id: 'Linn Valley', name: 'Linn Valley', region: 'Central' },
  { _id: 'Lisburn', name: 'Lisburn', region: 'Edmonton' },
  { _id: 'Little Buffalo', name: 'Little Buffalo', region: 'Northwest' },
  { _id: 'Little Gem', name: 'Little Gem', region: 'Central' },
  { _id: 'Little Smoky', name: 'Little Smoky', region: 'Northwest' },
  { _id: 'Lloydminster', name: 'Lloydminster', region: 'Central' },
  { _id: 'Lloyds Hill', name: 'Lloyds Hill', region: 'Central' },
  { _id: 'Lodgepole', name: 'Lodgepole', region: 'Central' },
  { _id: 'Lomond', name: 'Lomond', region: 'Calgary' },
  { _id: 'Lone Pine', name: 'Lone Pine', region: 'Northwest' },
  { _id: 'Long Lake', name: 'Long Lake', region: 'Northeast' },
  { _id: 'Longview', name: 'Longview', region: 'Calgary' },
  { _id: 'Looma', name: 'Looma', region: 'Edmonton' },
  { _id: 'Loon Lake', name: 'Loon Lake', region: 'Northwest' },
  { _id: 'Lottie Lake', name: 'Lottie Lake', region: 'Northeast' },
  { _id: 'Lougheed', name: 'Lougheed', region: 'Central' },
  { _id: 'Lousana', name: 'Lousana', region: 'Central' },
  { _id: 'Loyalist', name: 'Loyalist', region: 'Central' },
  { _id: 'Lundbreck', name: 'Lundbreck', region: 'South' },
  { _id: 'Lunnford', name: 'Lunnford', region: 'Edmonton' },
  { _id: 'Lure', name: 'Lure', region: 'Central' },
  { _id: 'Luscar', name: 'Luscar', region: 'Northwest' },
  { _id: 'Lutose', name: 'Lutose', region: 'Northwest' },
  { _id: 'Lyalta', name: 'Lyalta', region: 'Calgary' },
  { _id: 'Lymburn', name: 'Lymburn', region: 'Northwest' },
  { _id: 'Ma Me O Beach', name: 'Ma Me O Beach', region: 'Central' },
  { _id: 'MacKay', name: 'MacKay', region: 'Edmonton' },
  { _id: 'Madden', name: 'Madden', region: 'Calgary' },
  { _id: 'Magrath', name: 'Magrath', region: 'South' },
  { _id: 'Makepeace', name: 'Makepeace', region: 'Calgary' },
  { _id: 'Maleb', name: 'Maleb', region: 'South' },
  { _id: 'Mallaig', name: 'Mallaig', region: 'Northeast' },
  { _id: 'Mallow', name: 'Mallow', region: 'South' },
  { _id: 'Manning', name: 'Manning', region: 'Northwest' },
  { _id: 'Mannville', name: 'Mannville', region: 'Northeast' },
  { _id: 'Manola', name: 'Manola', region: 'Northeast' },
  { _id: 'Manyberries', name: 'Manyberries', region: 'South' },
  { _id: 'Mapova', name: 'Mapova', region: 'Northeast' },
  { _id: 'Marie Reine', name: 'Marie Reine', region: 'Northwest' },
  { _id: 'Markerville', name: 'Markerville', region: 'Central' },
  { _id: 'Marlboro', name: 'Marlboro', region: 'Northwest' },
  { _id: 'Marten Beach', name: 'Marten Beach', region: 'Northwest' },
  { _id: 'Marwayne', name: 'Marwayne', region: 'Central' },
  { _id: 'Maskwacis', name: 'Maskwacis', region: 'Central' },
  { _id: 'Maycroft', name: 'Maycroft', region: 'South' },
  { _id: 'Mayerthorpe', name: 'Mayerthorpe', region: 'Northwest' },
  { _id: 'McLaughlin', name: 'McLaughlin', region: 'Central' },
  { _id: 'McLennan', name: 'McLennan', region: 'Northwest' },
  { _id: 'McLeod Valley', name: 'McLeod Valley', region: 'Northwest' },
  { _id: 'McNab', name: 'McNab', region: 'South' },
  { _id: 'McNeil', name: 'McNeil', region: 'South' },
  { _id: 'McRae', name: 'McRae', region: 'Northeast' },
  { _id: 'Meadowview', name: 'Meadowview', region: 'Northeast' },
  { _id: 'Meander River', name: 'Meander River', region: 'Northwest' },
  { _id: 'Meanook', name: 'Meanook', region: 'Northeast' },
  { _id: 'Mearns', name: 'Mearns', region: 'Edmonton' },
  { _id: 'Medicine Hat', name: 'Medicine Hat', region: 'South' },
  { _id: 'Medicine Lodge', name: 'Medicine Lodge', region: 'Northwest' },
  { _id: 'Meeting Creek', name: 'Meeting Creek', region: 'Central' },
  { _id: 'Mellowdale', name: 'Mellowdale', region: 'Northeast' },
  { _id: 'Mercoal', name: 'Mercoal', region: 'Northwest' },
  { _id: 'Metiskow', name: 'Metiskow', region: 'Central' },
  { _id: 'Mewatha Beach', name: 'Mewatha Beach', region: 'Northeast' },
  { _id: 'Michichi', name: 'Michichi', region: 'Central' },
  { _id: 'Middle Creek', name: 'Middle Creek', region: 'Northeast' },
  { _id: 'Midland', name: 'Midland', region: 'Central' },
  { _id: 'Miette', name: 'Miette', region: 'Northwest' },
  { _id: 'Mildred Lake', name: 'Mildred Lake', region: 'Northeast' },
  { _id: 'Milk River', name: 'Milk River', region: 'South' },
  { _id: 'Millarville', name: 'Millarville', region: 'Calgary' },
  { _id: 'Millet', name: 'Millet', region: 'Central' },
  { _id: 'Millicent', name: 'Millicent', region: 'South' },
  { _id: 'Milo', name: 'Milo', region: 'Calgary' },
  { _id: 'Minaret', name: 'Minaret', region: 'Central' },
  { _id: 'Minburn', name: 'Minburn', region: 'Northeast' },
  { _id: 'Mirror', name: 'Mirror', region: 'Central' },
  { _id: 'Mission Beach', name: 'Mission Beach', region: 'Edmonton' },
  { _id: 'Mitsue', name: 'Mitsue', region: 'Northwest' },
  { _id: 'Monarch', name: 'Monarch', region: 'South' },
  { _id: 'Monitor', name: 'Monitor', region: 'Central' },
  { _id: 'Moon Lake', name: 'Moon Lake', region: 'Edmonton' },
  { _id: 'Moon River Estates', name: 'Moon River Estates', region: 'South' },
  { _id: 'Moose Wallow', name: 'Moose Wallow', region: 'Northwest' },
  { _id: 'Morecambe', name: 'Morecambe', region: 'Northeast' },
  { _id: 'Morinville', name: 'Morinville', region: 'Edmonton' },
  { _id: 'Morningside', name: 'Morningside', region: 'Central' },
  { _id: 'Morrin', name: 'Morrin', region: 'Central' },
  { _id: 'Mosside', name: 'Mosside', region: 'Northeast' },
  { _id: 'Mossleigh', name: 'Mossleigh', region: 'Calgary' },
  { _id: 'Mountain Park', name: 'Mountain Park', region: 'Northwest' },
  { _id: 'Mountain View', name: 'Mountain View', region: 'South' },
  { _id: 'Mulhurst Bay', name: 'Mulhurst Bay', region: 'Central' },
  { _id: 'Mundare', name: 'Mundare', region: 'Northeast' },
  { _id: 'Munson', name: 'Munson', region: 'Central' },
  { _id: 'Muriel', name: 'Muriel', region: 'Northeast' },
  { _id: 'Musidora', name: 'Musidora', region: 'Northeast' },
  { _id: 'Myrnam', name: 'Myrnam', region: 'Northeast' },
  { _id: 'Mystery Lake', name: 'Mystery Lake', region: 'Northwest' },
  { _id: 'Morley', name: 'Mînî Thnî', region: 'Calgary' },
  { _id: 'Nacmine', name: 'Nacmine', region: 'Central' },
  { _id: 'Naco', name: 'Naco', region: 'Central' },
  { _id: 'Nakamun', name: 'Nakamun', region: 'Northeast' },
  { _id: 'Namaka', name: 'Namaka', region: 'Calgary' },
  { _id: 'Namao', name: 'Namao', region: 'Edmonton' },
  { _id: 'Nampa', name: 'Nampa', region: 'Northwest' },
  { _id: 'Nanton', name: 'Nanton', region: 'Calgary' },
  { _id: 'Naples', name: 'Naples', region: 'Northeast' },
  { _id: 'Naylor Hills', name: 'Naylor Hills', region: 'Northwest' },
  { _id: 'Neerlandia', name: 'Neerlandia', region: 'Northeast' },
  { _id: 'Nemiskam', name: 'Nemiskam', region: 'South' },
  { _id: 'Nestow', name: 'Nestow', region: 'Northeast' },
  { _id: 'Netook', name: 'Netook', region: 'Central' },
  { _id: 'Neutral Hills', name: 'Neutral Hills', region: 'Central' },
  { _id: 'Nevis', name: 'Nevis', region: 'Central' },
  { _id: 'New Brigden', name: 'New Brigden', region: 'South' },
  { _id: 'New Dayton', name: 'New Dayton', region: 'South' },
  { _id: 'New Fish Creek', name: 'New Fish Creek', region: 'Northwest' },
  { _id: 'New Norway', name: 'New Norway', region: 'Central' },
  { _id: 'New Rockport', name: 'New Rockport', region: 'South' },
  { _id: 'New Sarepta', name: 'New Sarepta', region: 'Edmonton' },
  { _id: 'Newbrook', name: 'Newbrook', region: 'Northeast' },
  { _id: 'Newcastle Mine', name: 'Newcastle Mine', region: 'Central' },
  { _id: 'Nightingale', name: 'Nightingale', region: 'Calgary' },
  { _id: 'Ninastoko', name: 'Ninastoko', region: 'South' },
  { _id: 'Niobe', name: 'Niobe', region: 'Central' },
  { _id: 'Nisku', name: 'Nisku', region: 'Edmonton' },
  { _id: 'Niton Junction', name: 'Niton Junction', region: 'Northwest' },
  { _id: 'Nobleford', name: 'Nobleford', region: 'South' },
  { _id: 'Nojack', name: 'Nojack', region: 'Edmonton' },
  { _id: 'Nolan', name: 'Nolan', region: 'South' },
  { _id: 'Noral', name: 'Noral', region: 'Northeast' },
  { _id: 'Norbuck', name: 'Norbuck', region: 'Central' },
  { _id: 'Nordegg', name: 'Nordegg', region: 'Central' },
  { _id: 'Norglenwold', name: 'Norglenwold', region: 'Central' },
  { _id: 'Norma', name: 'Norma', region: 'Northeast' },
  { _id: 'Normandeau', name: 'Normandeau', region: 'Northeast' },
  { _id: 'Norris Beach', name: 'Norris Beach', region: 'Central' },
  { _id: 'Norta Fork', name: 'Norta Fork', region: 'South' },
  { _id: 'North Cooking Lake', name: 'North Cooking Lake', region: 'Edmonton' },
  { _id: 'North Shore Heights', name: 'North Shore Heights', region: 'Northeast' },
  { _id: 'North Star', name: 'North Star', region: 'Northwest' },
  { _id: 'Northern Valley', name: 'Northern Valley', region: 'Northeast' },
  { _id: 'Northmark', name: 'Northmark', region: 'Northwest' },
  { _id: 'Northville', name: 'Northville', region: 'Central' },
  { _id: 'Notikewan', name: 'Notikewan', region: 'Northwest' },
  { _id: 'Noyes Crossing', name: 'Noyes Crossing', region: 'Edmonton' },
  { _id: "O'Chiese", name: "O'Chiese", region: 'Central' },
  { _id: 'Obed', name: 'Obed', region: 'Northwest' },
  { _id: 'Oberlin', name: 'Oberlin', region: 'Central' },
  { _id: 'Ohaton', name: 'Ohaton', region: 'Central' },
  { _id: 'Oke', name: 'Oke', region: 'Northwest' },
  { _id: 'Okotoks', name: 'Okotoks', region: 'Calgary' },
  { _id: 'Olds', name: 'Olds', region: 'Central' },
  { _id: 'Onefour', name: 'Onefour', region: 'South' },
  { _id: 'Onion Lake', name: 'Onion Lake', region: 'Central' },
  { _id: 'Onoway', name: 'Onoway', region: 'Northeast' },
  { _id: 'Opal', name: 'Opal', region: 'Northeast' },
  { _id: 'Orion', name: 'Orion', region: 'South' },
  { _id: 'Orton', name: 'Orton', region: 'Calgary' },
  //   {
  //       _id: "Orton",
  //       name: "Orton",
  //       region: "South"
  //   } ,
  { _id: 'Overlea', name: 'Overlea', region: 'Northwest' },
  { _id: 'Owendale', name: 'Owendale', region: 'South' },
  { _id: 'Owlseye', name: 'Owlseye', region: 'Northeast' },
  { _id: 'Oyen', name: 'Oyen', region: 'South' },
  { _id: 'Paddle Prairie', name: 'Paddle Prairie', region: 'Northwest' },
  { _id: 'Paddle Prairie Metis Settlement', name: 'Paddle Prairie Metis Settlement', region: 'Northwest' },
  { _id: 'Pakowki', name: 'Pakowki', region: 'South' },
  { _id: 'Paradise Valley', name: 'Paradise Valley', region: 'Central' },
  { _id: 'Parkbend', name: 'Parkbend', region: 'South' },
  { _id: 'Parkland', name: 'Parkland', region: 'Calgary' },
  { _id: 'Parkland Beach', name: 'Parkland Beach', region: 'Central' },
  { _id: 'Pashley', name: 'Pashley', region: 'South' },
  { _id: 'Patricia', name: 'Patricia', region: 'South' },
  { _id: 'Paxson', name: 'Paxson', region: 'Northeast' },
  { _id: 'Peace Point', name: 'Peace Point', region: 'Northeast' },
  { _id: 'Peace River', name: 'Peace River', region: 'Northwest' },
  { _id: 'Pearce', name: 'Pearce', region: 'South' },
  { _id: 'Peavine', name: 'Peavine', region: 'Northwest' },
  { _id: 'Peavine Metis Settlement', name: 'Peavine Metis Settlement', region: 'Northwest' },
  { _id: 'Pedley', name: 'Pedley', region: 'Northwest' },
  { _id: 'Peerless Lake', name: 'Peerless Lake', region: 'Northwest' },
  { _id: 'Peers', name: 'Peers', region: 'Northwest' },
  { _id: 'Peigan', name: 'Peigan', region: 'South' },
  { _id: 'Pelican Narrows', name: 'Pelican Narrows', region: 'Northeast' },
  { _id: 'Pelican Portage', name: 'Pelican Portage', region: 'Northeast' },
  { _id: 'Pembina Heights', name: 'Pembina Heights', region: 'Northeast' },
  { _id: 'Pembridge', name: 'Pembridge', region: 'Edmonton' },
  { _id: 'Pemukan', name: 'Pemukan', region: 'Central' },
  { _id: "Pendant D'Oreille", name: "Pendant D'Oreille", region: 'South' },
  { _id: 'Pendryl', name: 'Pendryl', region: 'Central' },
  { _id: 'Penhold', name: 'Penhold', region: 'Central' },
  { _id: 'Peno', name: 'Peno', region: 'Northeast' },
  { _id: 'Peoria', name: 'Peoria', region: 'Northwest' },
  { _id: 'Perryvale', name: 'Perryvale', region: 'Northeast' },
  { _id: 'Philomena', name: 'Philomena', region: 'Northeast' },
  { _id: 'Pibroch', name: 'Pibroch', region: 'Northeast' },
  { _id: 'Pickardville', name: 'Pickardville', region: 'Northeast' },
  { _id: 'Picture Butte', name: 'Picture Butte', region: 'South' },
  { _id: 'Pincher Creek', name: 'Pincher Creek', region: 'South' },
  { _id: 'Pincher Station', name: 'Pincher Station', region: 'South' },
  { _id: 'Pine Lake', name: 'Pine Lake', region: 'Central' },
  { _id: 'Pingle', name: 'Pingle', region: 'Northeast' },
  { _id: 'Pioneer', name: 'Pioneer', region: 'Edmonton' },
  { _id: 'Pipestone Creek', name: 'Pipestone Creek', region: 'Northwest' },
  { _id: 'Pitlochrie', name: 'Pitlochrie', region: 'Northeast' },
  { _id: 'Pivot', name: 'Pivot', region: 'South' },
  { _id: 'Piyami', name: 'Piyami', region: 'South' },
  { _id: 'Plamondon', name: 'Plamondon', region: 'Northeast' },
  { _id: 'Poe', name: 'Poe', region: 'Central' },
  { _id: 'Point Alison', name: 'Point Alison', region: 'Edmonton' },
  { _id: 'Pollockville', name: 'Pollockville', region: 'South' },
  { _id: 'Ponoka', name: 'Ponoka', region: 'Central' },
  { _id: 'Poplar Bay', name: 'Poplar Bay', region: 'Central' },
  { _id: 'Poplar Ridge', name: 'Poplar Ridge', region: 'Central' },
  { _id: 'Prairie Creek', name: 'Prairie Creek', region: 'Central' },
  { _id: 'Prentiss', name: 'Prentiss', region: 'Central' },
  { _id: 'Prestville', name: 'Prestville', region: 'Northwest' },
  { _id: 'Priddis', name: 'Priddis', region: 'Calgary' },
  { _id: 'Priddis Greens', name: 'Priddis Greens', region: 'Calgary' },
  { _id: 'Princess', name: 'Princess', region: 'South' },
  { _id: 'Prosperity', name: 'Prosperity', region: 'Northeast' },
  { _id: 'Provost', name: 'Provost', region: 'Central' },
  { _id: 'Pulteney', name: 'Pulteney', region: 'Calgary' },
  { _id: 'Purple Springs', name: 'Purple Springs', region: 'South' },
  { _id: 'Queenstown', name: 'Queenstown', region: 'Calgary' },
  { _id: 'Quigley', name: 'Quigley', region: 'Northeast' },
  { _id: 'Radway', name: 'Radway', region: 'Northeast' },
  { _id: 'Rainbow Lake', name: 'Rainbow Lake', region: 'Northwest' },
  { _id: 'Rainier', name: 'Rainier', region: 'South' },
  { _id: 'Raley', name: 'Raley', region: 'South' },
  { _id: 'Ralston', name: 'Ralston', region: 'South' },
  { _id: 'Ranfurly', name: 'Ranfurly', region: 'Northeast' },
  { _id: 'Rangeton', name: 'Rangeton', region: 'Northeast' },
  { _id: 'Raven', name: 'Raven', region: 'Central' },
  { _id: 'Raymond', name: 'Raymond', region: 'South' },
  { _id: 'Red Deer', name: 'Red Deer', region: 'Central' },
  { _id: 'Red Deer Junction', name: 'Red Deer Junction', region: 'Central' },
  { _id: 'Red Earth Creek', name: 'Red Earth Creek', region: 'Northwest' },
  { _id: 'Red Willow', name: 'Red Willow', region: 'Central' },
  { _id: 'Redcliff', name: 'Redcliff', region: 'South' },
  { _id: 'Redwater', name: 'Redwater', region: 'Edmonton' },
  { _id: 'Redwood Meadows', name: 'Redwood Meadows', region: 'Calgary' },
  { _id: 'Reno', name: 'Reno', region: 'Northwest' },
  { _id: 'Retlaw', name: 'Retlaw', region: 'South' },
  { _id: 'Ribstone', name: 'Ribstone', region: 'Central' },
  { _id: 'Rich Lake', name: 'Rich Lake', region: 'Northeast' },
  { _id: 'Rich Valley', name: 'Rich Valley', region: 'Northeast' },
  { _id: 'Richdale', name: 'Richdale', region: 'South' },
  { _id: 'Ricinus', name: 'Ricinus', region: 'Central' },
  { _id: 'Ridgevalley', name: 'Ridgevalley', region: 'Northwest' },
  { _id: 'Rife', name: 'Rife', region: 'Northeast' },
  { _id: 'Rimbey', name: 'Rimbey', region: 'Central' },
  { _id: 'Rivercourse', name: 'Rivercourse', region: 'Central' },
  { _id: 'Riverview', name: 'Riverview', region: 'Northeast' },
  { _id: 'Riviere Qui Barre', name: 'Riviere Qui Barre', region: 'Edmonton' },
  { _id: 'Robb', name: 'Robb', region: 'Northwest' },
  { _id: 'Rochester', name: 'Rochester', region: 'Northeast' },
  { _id: 'Rochfort Bridge', name: 'Rochfort Bridge', region: 'Northwest' },
  { _id: 'Rochon Sands', name: 'Rochon Sands', region: 'Central' },
  { _id: 'Rocky Lane', name: 'Rocky Lane', region: 'Northwest' },
  { _id: 'Rocky Mtn House', name: 'Rocky Mountain House', region: 'Central' },
  { _id: 'Rocky Rapids', name: 'Rocky Rapids', region: 'Central' },
  { _id: 'Rocky View', name: 'Rocky View', region: 'Central' },
  { _id: 'Rockyford', name: 'Rockyford', region: 'Calgary' },
  { _id: 'Rolling Hills', name: 'Rolling Hills', region: 'South' },
  { _id: 'Rolly View', name: 'Rolly View', region: 'Edmonton' },
  { _id: 'Roma', name: 'Roma', region: 'Northwest' },
  { _id: 'Ronalane', name: 'Ronalane', region: 'South' },
  { _id: 'Rosalind', name: 'Rosalind', region: 'Central' },
  { _id: 'Rose Lynn', name: 'Rose Lynn', region: 'Central' },
  { _id: 'Rosebud', name: 'Rosebud', region: 'Calgary' },
  { _id: 'Rosedale', name: 'Rosedale', region: 'Central' },
  { _id: 'Rosedale Station', name: 'Rosedale Station', region: 'Central' },
  { _id: 'Rosedale Valley', name: 'Rosedale Valley', region: 'Central' },
  { _id: 'Roselea', name: 'Roselea', region: 'Northeast' },
  { _id: 'Rosemary', name: 'Rosemary', region: 'South' },
  { _id: 'Rosevear', name: 'Rosevear', region: 'Northwest' },
  { _id: 'Ross Haven', name: 'Ross Haven', region: 'Northeast' },
  { _id: 'Rossington', name: 'Rossington', region: 'Northeast' },
  { _id: 'Rosyth', name: 'Rosyth', region: 'Central' },
  { _id: 'Round Hill', name: 'Round Hill', region: 'Central' },
  { _id: 'Rowley', name: 'Rowley', region: 'Central' },
  { _id: 'Roxanna', name: 'Roxanna', region: 'Northwest' },
  { _id: 'Royal Park', name: 'Royal Park', region: 'Northeast' },
  { _id: 'Royalties', name: 'Royalties', region: 'Calgary' },
  { _id: 'Rumsey', name: 'Rumsey', region: 'Central' },
  { _id: 'Rusylvia', name: 'Rusylvia', region: 'Northeast' },
  { _id: 'Rycroft', name: 'Rycroft', region: 'Northwest' },
  { _id: 'Ryley', name: 'Ryley', region: 'Central' },
  { _id: 'Saddle Lake', name: 'Saddle Lake', region: 'Northeast' },
  { _id: 'Saint Francis', name: 'Saint Francis', region: 'Edmonton' },
  { _id: 'Salt Prairie', name: 'Salt Prairie', region: 'Northwest' },
  { _id: 'Sandy Beach', name: 'Sandy Beach', region: 'Northeast' },
  { _id: 'Sandy Lake', name: 'Sandy Lake', region: 'Northwest' },
  { _id: 'Sandy Rapids', name: 'Sandy Rapids', region: 'Edmonton' },
  { _id: 'Sangudo', name: 'Sangudo', region: 'Northeast' },
  { _id: 'Saulteaux', name: 'Saulteaux', region: 'Northwest' },
  { _id: 'Saunders', name: 'Saunders', region: 'Central' },
  { _id: 'Sawback', name: 'Sawback', region: 'Calgary' },
  { _id: 'Scandia', name: 'Scandia', region: 'South' },
  { _id: 'Scapa', name: 'Scapa', region: 'Central' },
  { _id: 'Schuler', name: 'Schuler', region: 'South' },
  { _id: 'Scollard', name: 'Scollard', region: 'Central' },
  { _id: 'Scotfield', name: 'Scotfield', region: 'Central' },
  { _id: 'Seba Beach', name: 'Seba Beach', region: 'Edmonton' },
  { _id: 'Sedalia', name: 'Sedalia', region: 'South' },
  { _id: 'Sedgewick', name: 'Sedgewick', region: 'Central' },
  { _id: 'Seebe', name: 'Seebe', region: 'Calgary' },
  { _id: 'Sentinel', name: 'Sentinel', region: 'Central' },
  { _id: 'Seven Persons', name: 'Seven Persons', region: 'South' },
  { _id: 'Sexsmith', name: 'Sexsmith', region: 'Northwest' },
  { _id: 'Shalka', name: 'Shalka', region: 'Northeast' },
  { _id: 'Sharples', name: 'Sharples', region: 'Central' },
  { _id: 'Sharrow', name: 'Sharrow', region: 'South' },
  { _id: 'Shaughnessy', name: 'Shaughnessy', region: 'South' },
  { _id: 'Shaw', name: 'Shaw', region: 'Northwest' },
  { _id: 'Sheerness', name: 'Sheerness', region: 'Central' },
  { _id: 'Shepard', name: 'Shepard', region: 'Calgary' },
  { _id: 'Sherwood Park', name: 'Sherwood Park', region: 'Edmonton' },
  { _id: 'Shining Bank', name: 'Shining Bank', region: 'Northwest' },
  { _id: 'Shoal Creek', name: 'Shoal Creek', region: 'Northeast' },
  { _id: 'Shonts', name: 'Shonts', region: 'Central' },
  { _id: 'Shouldice', name: 'Shouldice', region: 'Calgary' },
  { _id: 'Sibbald', name: 'Sibbald', region: 'South' },
  { _id: 'Siksika', name: 'Siksika', region: 'Calgary' },
  { _id: 'Silver Beach', name: 'Silver Beach', region: 'Central' },
  { _id: 'Silver Heights', name: 'Silver Heights', region: 'Central' },
  { _id: 'Silver Sands', name: 'Silver Sands', region: 'Northeast' },
  { _id: 'Silver Valley', name: 'Silver Valley', region: 'Northwest' },
  { _id: 'Silverwood', name: 'Silverwood', region: 'Northwest' },
  { _id: 'Sion', name: 'Sion', region: 'Northeast' },
  { _id: 'Skaro', name: 'Skaro', region: 'Northeast' },
  { _id: 'Skiff', name: 'Skiff', region: 'South' },
  { _id: 'Slave Lake', name: 'Slave Lake', region: 'Northwest' },
  { _id: 'Slawa', name: 'Slawa', region: 'Northeast' },
  { _id: 'Smith', name: 'Smith', region: 'Northwest' },
  { _id: 'Smoky Lake', name: 'Smoky Lake', region: 'Northeast' },
  { _id: 'Solomon', name: 'Solomon', region: 'Northwest' },
  { _id: 'South Baptiste', name: 'South Baptiste', region: 'Northeast' },
  { _id: 'South Cooking Lake', name: 'South Cooking Lake', region: 'Edmonton' },
  { _id: 'South View', name: 'South View', region: 'Northeast' },
  { _id: 'Southesk', name: 'Southesk', region: 'South' },
  { _id: 'Spedden', name: 'Spedden', region: 'Northeast' },
  { _id: 'Spirit River', name: 'Spirit River', region: 'Northwest' },
  { _id: 'Spondin', name: 'Spondin', region: 'Central' },
  { _id: 'Spring Coulee', name: 'Spring Coulee', region: 'South' },
  { _id: 'Spring Lake', name: 'Spring Lake', region: 'Edmonton' },
  { _id: 'Springbrook', name: 'Springbrook', region: 'Central' },
  { _id: 'Springburn', name: 'Springburn', region: 'Northwest' },
  { _id: 'Spruce Field', name: 'Spruce Field', region: 'Northeast' },
  { _id: 'Spruce Grove', name: 'Spruce Grove', region: 'Edmonton' },
  { _id: 'Spruce Valley', name: 'Spruce Valley', region: 'Northeast' },
  { _id: 'Spruce View', name: 'Spruce View', region: 'Central' },
  { _id: 'Spurfield', name: 'Spurfield', region: 'Northwest' },
  { _id: 'Sputinow', name: 'Sputinow', region: 'Northeast' },
  { _id: 'St. Albert', name: 'St. Albert', region: 'Edmonton' },
  { _id: 'St. Brides', name: 'St. Brides', region: 'Northeast' },
  { _id: 'St. Edouard', name: 'St. Edouard', region: 'Northeast' },
  { _id: 'St. Isidore', name: 'St. Isidore', region: 'Northwest' },
  { _id: 'St. Lina', name: 'St. Lina', region: 'Northeast' },
  { _id: 'St. Michael', name: 'St. Michael', region: 'Northeast' },
  { _id: 'St. Paul', name: 'St. Paul', region: 'Northeast' },
  { _id: 'St. Vincent', name: 'St. Vincent', region: 'Northeast' },
  { _id: 'Stand-Off', name: 'Stand-Off', region: 'South' },
  { _id: 'Standard', name: 'Standard', region: 'Calgary' },
  { _id: 'Stanger', name: 'Stanger', region: 'Northeast' },
  { _id: 'Stanmore', name: 'Stanmore', region: 'Central' },
  { _id: 'Staplehurst', name: 'Staplehurst', region: 'Central' },
  { _id: 'Star', name: 'Star', region: 'Northeast' },
  { _id: 'Stauffer', name: 'Stauffer', region: 'Central' },
  { _id: 'Stavely', name: 'Stavely', region: 'Calgary' },
  { _id: 'Steen River', name: 'Steen River', region: 'Northwest' },
  { _id: 'Steeper', name: 'Steeper', region: 'Northwest' },
  { _id: 'Sterco', name: 'Sterco', region: 'Northwest' },
  { _id: 'Stettler', name: 'Stettler', region: 'Central' },
  { _id: 'Stewart', name: 'Stewart', region: 'South' },
  { _id: 'Stewartfield', name: 'Stewartfield', region: 'Northeast' },
  { _id: 'Stirling', name: 'Stirling', region: 'South' },
  { _id: 'Stolberg', name: 'Stolberg', region: 'Central' },
  { _id: 'Stony Plain', name: 'Stony Plain', region: 'Edmonton' },
  { _id: 'Strachan', name: 'Strachan', region: 'Central' },
  { _id: 'Strangmuir', name: 'Strangmuir', region: 'Calgary' },
  { _id: 'Strathmore', name: 'Strathmore', region: 'Calgary' },
  { _id: 'Streamstown', name: 'Streamstown', region: 'Central' },
  { _id: 'Strome', name: 'Strome', region: 'Central' },
  { _id: 'Stry', name: 'Stry', region: 'Northeast' },
  { _id: 'Styal', name: 'Styal', region: 'Edmonton' },
  { _id: 'Suffield', name: 'Suffield', region: 'South' },
  { _id: 'Sugden', name: 'Sugden', region: 'Northeast' },
  { _id: 'Sunbreaker Cove', name: 'Sunbreaker Cove', region: 'Central' },
  { _id: 'Sundance', name: 'Sundance', region: 'Edmonton' },
  { _id: 'Sundance Beach', name: 'Sundance Beach', region: 'Edmonton' },
  { _id: 'Sundre', name: 'Sundre', region: 'Central' },
  //   {
  //       _id: "Sunnybrook",
  //       name: "Sunnybrook",
  //       region: "Central"
  //   } ,
  { _id: 'Sunnybrook', name: 'Sunnybrook', region: 'Edmonton' },
  { _id: 'Sunnydale', name: 'Sunnydale', region: 'South' },
  { _id: 'Sunnynook', name: 'Sunnynook', region: 'South' },
  { _id: 'Sunnyside', name: 'Sunnyside', region: 'Calgary' },
  { _id: 'Sunnyslope', name: 'Sunnyslope', region: 'Central' },
  { _id: 'Sunrise Beach', name: 'Sunrise Beach', region: 'Northeast' },
  { _id: 'Sunset House', name: 'Sunset House', region: 'Northwest' },
  { _id: 'Sunset Point', name: 'Sunset Point', region: 'Northeast' },
  { _id: 'Swalwell', name: 'Swalwell', region: 'Central' },
  { _id: 'Swan Hills', name: 'Swan Hills', region: 'Northwest' },
  { _id: 'Swan Landing', name: 'Swan Landing', region: 'Northwest' },
  { _id: 'Sweet Grass Landing', name: 'Sweet Grass Landing', region: 'Northwest' },
  { _id: 'Sylvan Lake', name: 'Sylvan Lake', region: 'Central' },
  { _id: 'Taber', name: 'Taber', region: 'South' },
  { _id: 'Taimi', name: 'Taimi', region: 'Central' },
  { _id: 'Talbot', name: 'Talbot', region: 'Central' },
  { _id: 'Tangent', name: 'Tangent', region: 'Northwest' },
  { _id: 'Taplow', name: 'Taplow', region: 'Central' },
  { _id: 'Tar Island', name: 'Tar Island', region: 'Northeast' },
  { _id: 'Tawatinaw', name: 'Tawatinaw', region: 'Northeast' },
  { _id: 'Taylorville', name: 'Taylorville', region: 'South' },
  { _id: 'Teepee Creek', name: 'Teepee Creek', region: 'Northwest' },
  { _id: 'Tees', name: 'Tees', region: 'Central' },
  { _id: 'Telfordville', name: 'Telfordville', region: 'Edmonton' },
  { _id: 'Tempest', name: 'Tempest', region: 'South' },
  { _id: 'Tennion', name: 'Tennion', region: 'South' },
  { _id: 'Therien', name: 'Therien', region: 'Northeast' },
  { _id: 'Thorhild', name: 'Thorhild', region: 'Northeast' },
  { _id: 'Thorsby', name: 'Thorsby', region: 'Edmonton' },
  { _id: 'Three Hills', name: 'Three Hills', region: 'Central' },
  { _id: 'Throne', name: 'Throne', region: 'Central' },
  { _id: 'Thunder Lake', name: 'Thunder Lake', region: 'Northeast' },
  { _id: 'Tieland', name: 'Tieland', region: 'Northwest' },
  { _id: 'Tiger Lily', name: 'Tiger Lily', region: 'Northeast' },
  { _id: 'Tilley', name: 'Tilley', region: 'South' },
  { _id: 'Timeu', name: 'Timeu', region: 'Northwest' },
  { _id: 'Tod Creek', name: 'Tod Creek', region: 'South' },
  { _id: 'Tofield', name: 'Tofield', region: 'Central' },
  { _id: 'Tomahawk', name: 'Tomahawk', region: 'Edmonton' },
  { _id: 'Topland', name: 'Topland', region: 'Northwest' },
  { _id: 'Torlea', name: 'Torlea', region: 'Central' },
  { _id: 'Torrington', name: 'Torrington', region: 'Central' },
  { _id: 'Travers', name: 'Travers', region: 'Calgary' },
  { _id: 'Trefoil', name: 'Trefoil', region: 'South' },
  { _id: 'Triangle', name: 'Triangle', region: 'Northwest' },
  { _id: 'Trochu', name: 'Trochu', region: 'Central' },
  { _id: 'Trout Lake', name: 'Trout Lake', region: 'Northwest' },
  { _id: 'Truman', name: 'Truman', region: 'Northeast' },
  { _id: "Tsuu T'ina", name: "Tsuu T'ina", region: 'Calgary' },
  { _id: 'Tudor', name: 'Tudor', region: 'Calgary' },
  { _id: 'Tulliby Lake', name: 'Tulliby Lake', region: 'Central' },
  { _id: 'Turin', name: 'Turin', region: 'South' },
  { _id: 'Turner Valley', name: 'Turner Valley', region: 'Calgary' },
  { _id: 'Tuttle', name: 'Tuttle', region: 'Central' },
  { _id: 'Tweedie', name: 'Tweedie', region: 'Northeast' },
  { _id: 'Twin Butte', name: 'Twin Butte', region: 'South' },
  { _id: 'Twin River', name: 'Twin River', region: 'South' },
  { _id: 'Twining', name: 'Twining', region: 'Central' },
  { _id: 'Two Creeks', name: 'Two Creeks', region: 'Northwest' },
  { _id: 'Two Hills', name: 'Two Hills', region: 'Northeast' },
  { _id: 'Twomey', name: 'Twomey', region: 'Central' },
  { _id: 'Ukalta', name: 'Ukalta', region: 'Northeast' },
  { _id: 'Val Quentin', name: 'Val Quentin', region: 'Northeast' },
  { _id: 'Vale', name: 'Vale', region: 'South' },
  { _id: 'Valhalla', name: 'Valhalla', region: 'Northwest' },
  { _id: 'Valleyview', name: 'Valleyview', region: 'Northwest' },
  { _id: 'Vauxhall', name: 'Vauxhall', region: 'South' },
  { _id: 'Vega', name: 'Vega', region: 'Northwest' },
  { _id: 'Vegreville', name: 'Vegreville', region: 'Northeast' },
  { _id: 'Veinerville', name: 'Veinerville', region: 'South' },
  { _id: 'Venice', name: 'Venice', region: 'Northeast' },
  { _id: 'Verger', name: 'Verger', region: 'South' },
  { _id: 'Vermilion', name: 'Vermilion', region: 'Central' },
  { _id: 'Veteran', name: 'Veteran', region: 'Central' },
  { _id: 'Viewpoint', name: 'Viewpoint', region: 'Central' },
  { _id: 'Viking', name: 'Viking', region: 'Central' },
  { _id: 'Village at Pigeon Lake', name: 'Village at Pigeon Lake', region: 'Central' },
  { _id: 'Villeneuve', name: 'Villeneuve', region: 'Edmonton' },
  { _id: 'Vilna', name: 'Vilna', region: 'Northeast' },
  { _id: 'Vimy', name: 'Vimy', region: 'Northeast' },
  { _id: 'Violet Grove', name: 'Violet Grove', region: 'Central' },
  { _id: 'Vulcan', name: 'Vulcan', region: 'Calgary' },
  { _id: 'Wabamun', name: 'Wabamun', region: 'Edmonton' },
  { _id: 'Wabasca', name: 'Wabasca', region: 'Northwest' },
  { _id: 'Wagner', name: 'Wagner', region: 'Northwest' },
  { _id: 'Wainwright', name: 'Wainwright', region: 'Central' },
  { _id: 'Waiparous', name: 'Waiparous', region: 'Calgary' },
  { _id: 'Walsh', name: 'Walsh', region: 'South' },
  { _id: 'Wandering River', name: 'Wandering River', region: 'Northeast' },
  { _id: 'Wanham', name: 'Wanham', region: 'Northwest' },
  { _id: 'Warburg', name: 'Warburg', region: 'Edmonton' },
  { _id: 'Warden', name: 'Warden', region: 'Central' },
  { _id: 'Warden Junction', name: 'Warden Junction', region: 'Central' },
  { _id: 'Wardlow', name: 'Wardlow', region: 'South' },
  { _id: 'Warner', name: 'Warner', region: 'South' },
  { _id: 'Warrensville', name: 'Warrensville', region: 'Northwest' },
  { _id: 'Warspite', name: 'Warspite', region: 'Northeast' },
  { _id: 'Warwick', name: 'Warwick', region: 'Northeast' },
  { _id: 'Wasel', name: 'Wasel', region: 'Northeast' },
  { _id: 'Waskatenau', name: 'Waskatenau', region: 'Northeast' },
  { _id: 'Water Valley', name: 'Water Valley', region: 'Central' },
  { _id: 'Waterton Park', name: 'Waterton Park', region: 'South' },
  { _id: 'Waterways', name: 'Waterways', region: 'Northeast' },
  { _id: 'Watino', name: 'Watino', region: 'Northwest' },
  { _id: 'Watts', name: 'Watts', region: 'Central' },
  { _id: 'Waugh', name: 'Waugh', region: 'Northeast' },
  { _id: 'Wayne', name: 'Wayne', region: 'Central' },
  { _id: 'Weald', name: 'Weald', region: 'Northwest' },
  { _id: 'Weasel Creek', name: 'Weasel Creek', region: 'Northeast' },
  { _id: 'Webster', name: 'Webster', region: 'Northwest' },
  { _id: 'Welling', name: 'Welling', region: 'South' },
  { _id: 'Welling Station', name: 'Welling Station', region: 'South' },
  { _id: 'Wembley', name: 'Wembley', region: 'Northwest' },
  { _id: 'West Baptiste', name: 'West Baptiste', region: 'Northeast' },
  { _id: 'West Carseland', name: 'West Carseland', region: 'Calgary' },
  { _id: 'West Cove', name: 'West Cove', region: 'Northeast' },
  { _id: 'Westcott', name: 'Westcott', region: 'Central' },
  { _id: 'Western Monarch', name: 'Western Monarch', region: 'Central' },
  { _id: 'Westerose', name: 'Westerose', region: 'Central' },
  { _id: 'Westlock', name: 'Westlock', region: 'Northeast' },
  { _id: 'Westward Ho', name: 'Westward Ho', region: 'Central' },
  { _id: 'Wetaskiwin', name: 'Wetaskiwin', region: 'Central' },
  { _id: 'Whiskey Gap', name: 'Whiskey Gap', region: 'South' },
  { _id: 'Whispering Hills', name: 'Whispering Hills', region: 'Northeast' },
  { _id: 'White Gull', name: 'White Gull', region: 'Northeast' },
  { _id: 'White Sands', name: 'White Sands', region: 'Central' },
  { _id: 'Whitecourt', name: 'Whitecourt', region: 'Northwest' },
  { _id: 'Whitelaw', name: 'Whitelaw', region: 'Northwest' },
  { _id: 'Whitford', name: 'Whitford', region: 'Northeast' },
  { _id: 'Whitla', name: 'Whitla', region: 'South' },
  { _id: 'Whitney', name: 'Whitney', region: 'South' },
  { _id: 'Widewater', name: 'Widewater', region: 'Northwest' },
  { _id: 'Wildhorse', name: 'Wildhorse', region: 'South' },
  { _id: 'Wildwood', name: 'Wildwood', region: 'Edmonton' },
  { _id: 'Willingdon', name: 'Willingdon', region: 'Northeast' },
  { _id: 'Willow Creek', name: 'Willow Creek', region: 'Calgary' },
  { _id: 'Wilson', name: 'Wilson', region: 'South' },
  { _id: 'Wimborne', name: 'Wimborne', region: 'Central' },
  { _id: 'Winagami', name: 'Winagami', region: 'Northwest' },
  { _id: 'Windfall', name: 'Windfall', region: 'Northwest' },
  { _id: 'Winfield', name: 'Winfield', region: 'Central' },
  { _id: 'Winnifred', name: 'Winnifred', region: 'South' },
  { _id: 'Withrow', name: 'Withrow', region: 'Central' },
  { _id: 'Woking', name: 'Woking', region: 'Northwest' },
  { _id: 'Woodhouse', name: 'Woodhouse', region: 'Calgary' },
  { _id: 'Woolchester', name: 'Woolchester', region: 'South' },
  { _id: 'Woolford', name: 'Woolford', region: 'South' },
  { _id: 'Worsley', name: 'Worsley', region: 'Northwest' },
  { _id: 'Wostok', name: 'Wostok', region: 'Northeast' },
  { _id: 'Wrentham', name: 'Wrentham', region: 'South' },
  { _id: 'Wynd', name: 'Wynd', region: 'Northwest' },
  { _id: 'Yates', name: 'Yates', region: 'Northwest' },
  { _id: 'Yellowstone', name: 'Yellowstone', region: 'Northeast' },
  { _id: 'Yeoford', name: 'Yeoford', region: 'Central' },
  { _id: 'Youngstown', name: 'Youngstown', region: 'South' },
  { _id: 'Zama City', name: 'Zama City', region: 'Northwest' },
  { _id: 'Zama Lake', name: 'Zama Lake', region: 'Northwest' },
];

export const Counties = [
  { _id: 'Athabasca County', name: 'Athabasca County' },
  { _id: 'Beaver County', name: 'Beaver County' },
  { _id: 'Birch Hills County', name: 'Birch Hills County' },
  { _id: 'Brazeau County', name: 'Brazeau County' },
  { _id: 'Camrose County', name: 'Camrose County' },
  { _id: 'Cardston County', name: 'Cardston County' },
  { _id: 'Clear Hills County', name: 'Clear Hills County' },
  { _id: 'Clearwater County', name: 'Clearwater County' },
  { _id: 'County of Vermilion River', name: 'County of Vermilion River' },
  { _id: 'County of Barrhead No. 11', name: 'County of Barrhead No. 11' },
  { _id: 'County of Forty Mile No. 8', name: 'County of Forty Mile No. 8' },
  { _id: 'County of Grande Prairie No. 1', name: 'County of Grande Prairie No. 1' },
  { _id: 'County of Minburn No. 27', name: 'County of Minburn No. 27' },
  { _id: 'County of Newell', name: 'County of Newell' },
  { _id: 'County of Northern Lights', name: 'County of Northern Lights' },
  { _id: 'County of Paintearth No. 18', name: 'County of Paintearth No. 18' },
  { _id: 'County of St Paul No. 19', name: 'County of St Paul No. 19' },
  { _id: 'County of Stettler No. 6', name: 'County of Stettler No. 6' },
  { _id: 'County of Two Hills No. 21', name: 'County of Two Hills No. 21' },
  { _id: 'County of Vermilion River', name: 'County of Vermilion River' },
  { _id: 'County of Warner No. 5', name: 'County of Warner No. 5' },
  { _id: 'County of Wetaskiwin No. 10', name: 'County of Wetaskiwin No. 10' },
  { _id: 'Cypress County', name: 'Cypress County' },
  { _id: 'East Coulee', name: 'East Coulee' },
  { _id: 'Flagstaff County', name: 'Flagstaff County' },
  { _id: 'Kneehill County', name: 'Kneehill County' },
  { _id: 'Lac La Biche County', name: 'Lac La Biche County' },
  { _id: 'Lac Ste. Anne County', name: 'Lac Ste. Anne County' },
  { _id: 'Lacombe County', name: 'Lacombe County' },
  { _id: 'Lamont County', name: 'Lamont County' },
  { _id: 'Lancaster Park', name: 'Lancaster Park' },
  { _id: 'Leduc County', name: 'Leduc County' },
  { _id: 'Lethbridge County', name: 'Lethbridge County' },
  { _id: 'Mackenzie County', name: 'Mackenzie County' },
  { _id: 'Mountain View County', name: 'Mountain View County' },
  { _id: 'Northern Sunrise County', name: 'Northern Sunrise County' },
  { _id: 'Parkland County', name: 'Parkland County' },
  { _id: 'Ponoka County', name: 'Ponoka County' },
  { _id: 'Red Deer County', name: 'Red Deer County' },
  { _id: 'Rocky View County', name: 'Rocky View County' },
  { _id: 'Saddle Hills County', name: 'Saddle Hills County' },
  { _id: 'Smoky Lake County', name: 'Smoky Lake County' },
  { _id: 'Starland County', name: 'Starland County' },
  { _id: 'Strathcona County', name: 'Strathcona County' },
  { _id: 'Sturgeon County', name: 'Sturgeon County' },
  { _id: 'Thorhild County', name: 'Thorhild County' },
  { _id: 'Vulcan County', name: 'Vulcan County' },
  { _id: 'Westlock County', name: 'Westlock County' },
  { _id: 'Wheatland County', name: 'Wheatland County' },
  { _id: 'Woodlands County', name: 'Woodlands County' },
  { _id: 'Yellowhead County', name: 'Yellowhead County' },
];
