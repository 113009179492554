import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DocumentsUpload, FiDocumentCategories, FiRelations, Genders, SELECT_ONE_TEXT } from '@common/constants';
import { AlbertaAddress, Document, PhoneNumber, User } from '@common/entities';
import { FiParentInfoLabels } from '@common/labels';
import { PersonalIdentifierValidator } from '@common/ui/validation';
import { FiParent, MAX_WIDTH_FOR_MOBILE } from '@fscd-intake/entities';
import { BaseStepComponent } from '../base-step/base-step.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'fi-parent-info',
  templateUrl: './parent-info.component.html',
  styleUrls: ['./parent-info.component.scss'],
})
export class ParentInfoComponent extends BaseStepComponent implements OnInit {
  private destroy$ = new Subject<void>();
  genderList = Genders;
  relationList = FiRelations;
  selectOneText = SELECT_ONE_TEXT;
  formChangeSubscription;
  documents = [] as Document[];
  hasIdentityDocument = false;
  fiParentInfoLabels = FiParentInfoLabels;

  @Input() applicationId: string;

  _loggedUserEmail: string;
  @Input() set profile(value: User) {
    if (value) {
      this._loggedUserEmail = value.email;
      this.setParentEmail(this._parentInfo?.email, this._loggedUserEmail);
    }
  }

  _parentInfo: FiParent;
  @Input() set parentInfo(value: FiParent) {
    this._parentInfo = value;
    if (value) {
      this.setParentEmail(value?.email, this._loggedUserEmail);
      this.setFormValue();
    }
  }

  @Input() set hasErrors(value: boolean) {
    if (value) {
      this.formGroup.markAllAsTouched();
      this.cdr.detectChanges();
    }
  }

  @Output() formError = new EventEmitter();
  @Output() formUpdated = new EventEmitter();

  _dateToday = new Date();
  _dateMin = new Date(this._dateToday.getFullYear() - 115, 0, 1);
  _dateMax = new Date();

  documentsFormControl = this.fb.control<Document[]>([]);
  documentsNeeded = {} as DocumentsUpload;

  formGroup = this.fb.group({
    firstName: this.fb.control('', [Validators.required, PersonalIdentifierValidator.legalNameValidator]),
    middleName: this.fb.control('', PersonalIdentifierValidator.legalNameValidator),
    lastName: this.fb.control('', [Validators.required, PersonalIdentifierValidator.legalNameValidator]),
    preferredName: this.fb.control('', [PersonalIdentifierValidator.legalNameValidator]),
    birthdate: this.fb.control<Date>(null),
    gender: this.fb.control<string>(null, Validators.required),
    isEnglishFirstLanguage: this.fb.control<boolean>(null, Validators.required),
    primaryLanguage: this.fb.control(''),
    requiresInterpreter: this.fb.control<boolean>(null),
    haveOtherCommunicationNeed: this.fb.control<boolean>(null, Validators.required),
    otherCommunicationNeed: this.fb.control('', Validators.required),
    phoneNumbers: this.fb.control<PhoneNumber[]>([], Validators.required),
    email: this.fb.control('', Validators.required),
    hasSameAddressAsChild: this.fb.control<boolean>(null, Validators.required),
    residentialAddress: this.fb.control<AlbertaAddress>(null),
    relationToChild: this.fb.control<string>(null, Validators.required),
    documents: this.documentsFormControl,
    learnAboutFscdPhysician: this.fb.control<boolean>(false),
    learnAboutFscdHospital: this.fb.control<boolean>(false),
    learnAboutFscdAgency: this.fb.control<boolean>(false),
    learnAboutFscdEarlyInterventionProgram: this.fb.control<boolean>(false),
    learnAboutFscdEasterSeals: this.fb.control<boolean>(false),
    learnAboutFscdHealthUnit: this.fb.control<boolean>(false),
    learnAboutFscdSchool: this.fb.control<boolean>(false),
    learnAboutFscdChildrensServices: this.fb.control<boolean>(false),
    learnAboutFscdOther: this.fb.control<boolean>(false),
    learnAboutFscdOtherDetail: this.fb.control('', Validators.required),
    hasSecondParent: this.fb.control<boolean>(false),
    secondParent: this.fb.group({
      firstName: this.fb.control('', [Validators.required, PersonalIdentifierValidator.legalNameValidator]),
      middleName: this.fb.control('', PersonalIdentifierValidator.legalNameValidator),
      lastName: this.fb.control('', [Validators.required, PersonalIdentifierValidator.legalNameValidator]),
      preferredName: this.fb.control('', [PersonalIdentifierValidator.legalNameValidator]),
      birthdate: this.fb.control<Date>(null),
      gender: this.fb.control<string>(null, Validators.required),
      isEnglishFirstLanguage: this.fb.control<boolean>(null, Validators.required),
      primaryLanguage: this.fb.control(''),
      requiresInterpreter: this.fb.control<boolean>(null),
      haveOtherCommunicationNeed: this.fb.control<boolean>(null, Validators.required),
      otherCommunicationNeed: this.fb.control('', Validators.required),
      phoneNumbers: this.fb.control<PhoneNumber[]>([], Validators.required),
      email: this.fb.control('', Validators.required),
      hasSameAddressAsChild: this.fb.control<boolean>(null, Validators.required),
      residentialAddress: this.fb.control<AlbertaAddress>(null),
      relationToChild: this.fb.control<string>(null, Validators.required),
    }),
  });

  constructor(fb: FormBuilder, public cdr: ChangeDetectorRef, public route: ActivatedRoute) {
    super(route, fb);
  }

  ngOnInit(): void {
    this.formChangeSubscription = this.formGroup.valueChanges.subscribe((val) => {
      this.formUpdated.emit(val);
    });
  }

  onHasSameAddressAsChildChange(hasSameAddressAsChild: boolean) {
    if (hasSameAddressAsChild) {
      this.removeControlFromForm(this.formGroup, 'residentialAddress', true);
    } else {
      this.addControlToForm(this.formGroup, 'residentialAddress', true);
      this.formGroup.get('residentialAddress').setValidators([Validators.required]);
    }
    this.formGroup.updateValueAndValidity();
  }

  onRelationChange(value) {
    this.hasIdentityDocument = this.hasDocument(this.documents, 'identification');
    if (value === 'other') {
      this.documentsNeeded = {
        identification: { ...FiDocumentCategories.parent.identification, isRemoving: false },
      };
    } else {
      if (this.hasIdentityDocument) {
        this.documentsNeeded['identification'].isRemoving = true;
      } else {
        delete this.documentsNeeded['identification'];
      }
    }
    this.cdr.detectChanges();
  }

  setParentEmail(parentEmail, loggedUserEmail) {
    const email = parentEmail ? parentEmail : loggedUserEmail;
    this.formGroup.patchValue({
      email: email,
    });
  }

  private setFormValue(): void {
    this.documents = [] as Document[];
    if (this.formGroup && this._parentInfo) {
      this.formGroup.patchValue({
        firstName: this._parentInfo.firstName,
        middleName: this._parentInfo.middleName,
        lastName: this._parentInfo.lastName,
        preferredName: this._parentInfo.preferredName,
        birthdate: this._parentInfo.birthdate,
        gender: this._parentInfo.gender,
        isEnglishFirstLanguage: this._parentInfo.isEnglishFirstLanguage,
        primaryLanguage: this._parentInfo?.primaryLanguage,
        requiresInterpreter: this._parentInfo?.requiresInterpreter,
        haveOtherCommunicationNeed: this._parentInfo.haveOtherCommunicationNeed,
        otherCommunicationNeed: this._parentInfo?.otherCommunicationNeed,
        phoneNumbers: this._parentInfo.phoneNumbers
          ? this._parentInfo.phoneNumbers.map((phoneNumber) => {
              return {
                phoneNumber: phoneNumber.phoneNumber,
                type: phoneNumber.type,
              };
            })
          : [{ phoneNumber: '', type: 'Mobile' }],
        hasSameAddressAsChild: this._parentInfo.hasSameAddressAsChild,
        residentialAddress: this._parentInfo.residentialAddress,
        relationToChild: this._parentInfo.relationToChild,
        learnAboutFscdPhysician: this._parentInfo.learnAboutFscdPhysician,
        learnAboutFscdHospital: this._parentInfo.learnAboutFscdHospital,
        learnAboutFscdAgency: this._parentInfo.learnAboutFscdAgency,
        learnAboutFscdEarlyInterventionProgram: this._parentInfo.learnAboutFscdEarlyInterventionProgram,
        learnAboutFscdEasterSeals: this._parentInfo.learnAboutFscdEasterSeals,
        learnAboutFscdHealthUnit: this._parentInfo.learnAboutFscdHealthUnit,
        learnAboutFscdSchool: this._parentInfo.learnAboutFscdSchool,
        learnAboutFscdChildrensServices: this._parentInfo.learnAboutFscdChildrensServices,
        learnAboutFscdOther: this._parentInfo.learnAboutFscdOther,
        learnAboutFscdOtherDetail: this._parentInfo.learnAboutFscdOtherDetail,
        hasSecondParent: this._parentInfo.hasSecondParent,
        secondParent: {
          firstName: this._parentInfo.secondParent?.firstName,
          middleName: this._parentInfo.secondParent?.middleName,
          lastName: this._parentInfo.secondParent?.lastName,
          preferredName: this._parentInfo.secondParent?.preferredName,
          birthdate: this._parentInfo.secondParent?.birthdate,
          gender: this._parentInfo.secondParent?.gender,
          isEnglishFirstLanguage: this._parentInfo.secondParent?.isEnglishFirstLanguage,
          primaryLanguage: this._parentInfo.secondParent?.primaryLanguage,
          requiresInterpreter: this._parentInfo.secondParent?.requiresInterpreter,
          haveOtherCommunicationNeed: this._parentInfo.secondParent?.haveOtherCommunicationNeed,
          otherCommunicationNeed: this._parentInfo.secondParent?.otherCommunicationNeed,
          phoneNumbers: this._parentInfo.secondParent?.phoneNumbers
            ? this._parentInfo.secondParent.phoneNumbers.map((phoneNumber) => {
                return {
                  phoneNumber: phoneNumber.phoneNumber,
                  type: phoneNumber.type,
                };
              })
            : [{ phoneNumber: '', type: '' }],
          email: this._parentInfo.secondParent?.email,
          hasSameAddressAsChild: this._parentInfo.secondParent?.hasSameAddressAsChild,
          residentialAddress: this._parentInfo.secondParent?.residentialAddress,
          relationToChild: this._parentInfo.secondParent?.relationToChild,
        },
      });

      this.onIsEnglishFirstLanguageChange(this._parentInfo.isEnglishFirstLanguage);
      this.onHaveOtherCommunicationNeedChange(this._parentInfo.haveOtherCommunicationNeed);
      if (this._parentInfo?.hasSecondParent) {
        this.secondParentIsEnglishFirstLanguageChange(this._parentInfo.secondParent.isEnglishFirstLanguage);
        this.secondParentOtherCommunicationNeedChange(this._parentInfo.secondParent.haveOtherCommunicationNeed);
      }

      if (!this._parentInfo.hasSameAddressAsChild) {
        this.formGroup.get('residentialAddress').setValidators([Validators.required]);
        this.formGroup.get('residentialAddress').markAsTouched();
        if (!this._parentInfo.residentialAddress) {
          this.formGroup.patchValue({
            residentialAddress: new AlbertaAddress(),
          });
        }
      }

      this.onRelationChange(this._parentInfo.relationToChild);

      if (this._parentInfo.documents) {
        this._parentInfo.documents.forEach((d) => this.documents.push({ ...d }));
        this.documentsFormControl.patchValue(this.documents);
        if (this.documents.length) this.cdr.detectChanges();
      }
      this.onLearnAboutFscdOtherChange(this._parentInfo.learnAboutFscdOther);
    }
    this.cdr.detectChanges();
  }

  onIsEnglishFirstLanguageChange(value: boolean) {
    if (value) {
      this.removeControlFromForm(this.formGroup, 'primaryLanguage');
      this.removeControlFromForm(this.formGroup, 'requiresInterpreter');
    } else {
      this.addControlToForm(this.formGroup, 'primaryLanguage', true);
      this.formGroup.get('primaryLanguage').setValidators(Validators.required);

      this.addControlToForm(this.formGroup, 'requiresInterpreter', true);
      this.formGroup.get('requiresInterpreter').setValidators(Validators.required);
    }
    this.formGroup.updateValueAndValidity();
  }

  secondParentIsEnglishFirstLanguageChange(value: boolean) {
    if (value) {
      this.removeControlFromForm(this.secondParentFG, 'primaryLanguage');
      this.removeControlFromForm(this.secondParentFG, 'requiresInterpreter');
    } else {
      this.addControlToForm(this.secondParentFG, 'primaryLanguage', true);
      this.secondParentFG.get('primaryLanguage').setValidators(Validators.required);

      this.addControlToForm(this.secondParentFG, 'requiresInterpreter', true);
      this.secondParentFG.get('requiresInterpreter').setValidators(Validators.required);
    }
    this.secondParentFG.updateValueAndValidity();
  }

  onHaveOtherCommunicationNeedChange(value: boolean) {
    if (value) {
      this.addControlToForm(this.formGroup, 'otherCommunicationNeed', true);
      this.formGroup.get('otherCommunicationNeed').setValidators(Validators.required);
    } else {
      this.removeControlFromForm(this.formGroup, 'otherCommunicationNeed', true);
    }
    this.formGroup.updateValueAndValidity();
  }

  secondParentOtherCommunicationNeedChange(value: boolean) {
    if (value) {
      this.addControlToForm(this.secondParentFG, 'otherCommunicationNeed', true);
      this.secondParentFG.get('otherCommunicationNeed').setValidators(Validators.required);
    } else {
      this.removeControlFromForm(this.secondParentFG, 'otherCommunicationNeed', true);
    }
    this.secondParentFG.updateValueAndValidity();
  }

  hasDocument(documents: Document[], documentCategory: string) {
    return documents.filter((doc) => doc.documentCategory === documentCategory).length > 0;
  }

  onRemoveFile(document: Document) {
    if (!this.documents.some((d) => d.documentCategory === document.documentCategory && !d.isDeleted))
      this.cdr.detectChanges();
  }

  onFileUploaded(document: Document) {
    this.documents.push(document);
    this.documentsFormControl.patchValue(this.documents);
    if (this.documents.filter((d) => d.documentCategory === document.documentCategory && !d.isDeleted).length === 1)
      this.cdr.detectChanges();
  }

  unsort() {
    return 0;
  }

  onLearnAboutFscdOtherChange(value: boolean) {
    if (value) {
      this.addControlToForm(this.formGroup, 'learnAboutFscdOtherDetail', true);
      this.formGroup.get('learnAboutFscdOtherDetail').setValidators(Validators.required);
    } else {
      this.removeControlFromForm(this.formGroup, 'learnAboutFscdOtherDetail', true);
    }
    this.formGroup.updateValueAndValidity();
  }

  toggleSecondParent(value: boolean) {
    this.formGroup.patchValue({
      hasSecondParent: value,
    });
    if (value === false) {
      this.formGroup.patchValue({
        secondParent: {
          firstName: null,
          middleName: null,
          lastName: null,
          preferredName: null,
          birthdate: null,
          gender: null,
          isEnglishFirstLanguage: null,
          primaryLanguage: null,
          requiresInterpreter: null,
          haveOtherCommunicationNeed: null,
          otherCommunicationNeed: null,
          phoneNumbers: [],
          email: null,
          hasSameAddressAsChild: null,
          residentialAddress: null,
          relationToChild: null,
        },
      });
    }
    this.formGroup.updateValueAndValidity();
  }

  get secondParentFG() {
    return this.formGroup.get('secondParent') as FormGroup;
  }

  @HostListener('window:resize', ['$event'])
  isScreenSmall(): boolean {
    return window.innerWidth <= MAX_WIDTH_FOR_MOBILE;
  }
}
