import { Utilities } from '@common/utils';
import { Component, Input, OnChanges } from '@angular/core';
import { FiRelations, Genders } from '@common/constants';
import { Document } from '@common/entities';
import { FileService } from '@common/ui/shared-components';
import { FiParent, FscdIntakeApplication } from '@fscd-intake/entities';
import { FiParentInfoLabels } from '@common/labels';

@Component({
  selector: 'fi-view-application-parent',
  templateUrl: './view-application-parent.component.html',
  styleUrls: [
    '../view-application.component.scss', 
    '../view-application-child-parent.component.scss'
  ],
})
export class ViewApplicationParentComponent implements OnChanges {
  @Input() application: FscdIntakeApplication;

  docArr = [];
  genderList = Genders;
  relationList = FiRelations;
  utility = Utilities;
  labels = FiParentInfoLabels;
  constructor(private fileService: FileService) {}

  ngOnChanges(): void {
    const documents = this.application?.parent?.documents || [];
    documents.forEach((doc) => {
      this.docArr.push({ str: doc.documentType || '-' });
      this.docArr.push({ str: doc.name || '-' });
      this.docArr.push({ isView: true, doc });
    });
  }

  onClickRowView(document: Document) {
    if (!document.key || !this.application.id) return;
    this.fileService.getDocument(document.key, this.application.id).subscribe((result) => {
      window.open(result['URL']);
    });
  }

  getLearnAboutFscdValues(parent: FiParent): string {
    if (!parent) return null;

    const returnValue = [];
    if (parent.learnAboutFscdPhysician) returnValue.push('Physician');
    if (parent.learnAboutFscdHospital) returnValue.push('Hospital');
    if (parent.learnAboutFscdAgency) returnValue.push('Agency');
    if (parent.learnAboutFscdEarlyInterventionProgram) returnValue.push('Early Intervention Program');
    if (parent.learnAboutFscdEasterSeals) returnValue.push('Easter Seals');
    if (parent.learnAboutFscdHealthUnit) returnValue.push('Health Unit');
    if (parent.learnAboutFscdSchool) returnValue.push('School');
    if (parent.learnAboutFscdChildrensServices) returnValue.push("Children's Services");

    if (returnValue.length > 0) {
      return returnValue.join(', ');
    }

    return null;
  }

  getLearnAboutFscdOtherValue(parent: FiParent): string {
    return `Other: ${parent.learnAboutFscdOtherDetail}`;
  }

  get secondParent() {
    return this.application.parent?.secondParent;
  }
}
