import { UploadLookup } from './upload-lookup.interface';
import { DocumentsUpload } from './documents-upload.interface';

//displayValue: is the title on the document uploader (ex: Copy of 'medical letter').
//  -Also shows in the documentType column in the PDF if no doc types selected (only 1 doc type).
//displayCategory: is the category column value in the PDF (only used in PDF). Friendly text to the documentCategory alias (this is needed for optOut)
//  -If this isn't specified, it will use the property alias transformed (notWorkingReasonPersonal) using startCase(camelCase('notWorkingReasonPersonal').
//optOutLabel: is the text for the opt out checkbox in the document loader.

export const IesDocumentCategories: { [name: string]: DocumentsUpload } = {
  mySituation: {
    evictionNotice: {
      displayValue: 'Eviction Notice',
      categoryTitleOverride: 'Upload Eviction Notice',
      documentTypes: ['Eviction Notice'],
      order: 1,
      optOutLabel:
        'I do not have an eviction notice and I understand that not providing this document with my online application will cause delays and may result in my application being withdrawn.',
      requiredErrorOverride: 'The eviction notice is missing. Upload now or check the box below.',
    },
    utilityDisconnectionNotice: {
      displayValue: 'Disconnection Notice',
      categoryTitleOverride: 'Upload Disconnection Notice',
      documentTypes: ['Disconnection Notice'],
      order: 1,
      optOutLabel:
        'I do not have a utility disconnect notice and I understand that not providing this document with my online application will cause delays and may result in my application being withdrawn.',
      requiredErrorOverride: 'The utility disconnect notice is missing. Upload now or check the box below.',
    },
  },
  applicant: {
    identification: {
      displayValue: 'Identification',
      documentTypes: [
        "Driver's license",
        'Non-driver government issued identification card',
        'Birth, marriage or baptismal certificate',
        'Employee or student card',
        'Band registry number, status card, or Metis card',
        'Alberta Personal Health Card',
        'Social Insurance Number',
      ],
      order: 1,
      optOutLabel:
        'I do not have any form of identification and I understand that not providing this document with my online application will cause delays and may result in my application being withdrawn.',
      requiredErrorOverride:
        'Proof of identification is missing. Upload now or if you do not have identification check the box below.',
    },
  },
  spousePartner: {
    identification: {
      displayValue: 'Identification',
      documentTypes: [
        "Driver's license",
        'Non-driver government issued identification card',
        'Birth, marriage or baptismal certificate',
        'Employee or student card',
        'Band registry number, status card, or Metis card',
        'Alberta Personal Health Card',
        'Social Insurance Number',
      ],
      order: 1,
      optOutLabel:
        'My <<spouseLabel>> does not have any form of identification and I understand that not providing this document with my online application will cause delays and may result in my application being withdrawn.',
      requiredErrorOverride:
        'Proof of identification is missing. Upload now or if your <<spouseLabel>> does not have identification check the box below.',
    },
    // permResidency: {
    //   displayValue: 'Permanent Residency',
    //   documentTypes: ['Confirmation of permanent residence ', 'Permanent resident card', 'Record of landing'],
    // },
  },
  dependent: {
    identification: {
      displayValue: 'Identification',
      documentTypes: [
        "Driver's license",
        'Non-driver identification card',
        'Birth, marriage or baptismal certificate',
        'Employee or student card',
        'Band registry number, status card, or Metis card',
        'Alberta Personal Health Card',
        'Social Insurance Number',
      ],
      order: 1,
      optOutLabel:
        'My child does not have any form of identification and I understand that not providing this document with my online application will cause delays and may result in my application being withdrawn.',
      requiredErrorOverride:
        'Proof of identification is missing. Upload now or if your child does not have identification check the box below.',
    },
    highSchoolDeclaration: {
      code: 'highSchoolDeclaration',
      displayValue: 'Declaration of 18/19 year old',
      documentTypes: ['Declaration of 18/19 year old'],
      displayCategory: 'Declaration of 18/19 year old',
      order: 2,
      optOutLabel:
        'I am not able to provide a declaration for my 18/19 year old child and I understand that not providing this document with my online application will cause delays and may result in my application being withdrawn.',
      requiredErrorOverride:
        'The declaration for your 18/19 year old child is missing, upload now or check the box below.',
      categoryTitleOverride: 'Declaration of 18/19 year old',
      hideDocumentTypeLabel: true,
    },
    biologicalAndAdoptiveParentDeclaration: {
      code: 'biologicalAndAdoptiveParentDeclaration',
      displayValue: 'Child support services form',
      documentTypes: ['Biological and Adoptive Parent Declaration'],
      order: 3,
      optOutLabel:
        'I am not able to provide a child support services form and I understand that not providing this document with my online application will cause delays and may result in my application being withdrawn.',
      requiredErrorOverride: 'The child support services form is missing, upload now or check the box below.',
      categoryTitleOverride: 'Child support services form',
      hideDocumentTypeLabel: true,
    },
  },
  employment: {
    notWorkingReasonPersonal: {
      displayValue: 'Medical Letter',
      displayCategory: 'Reason Not Working',
      documentTypes: ['Medical Letter'],
      order: 1,
      optOutLabel:
        'I do not have a medical letter and I understand that not providing this document with my online application will cause delays and may result in my application being withdrawn.',
      requiredErrorOverride: 'The medical letter is missing, upload now or check the box below.',
      categoryTitleOverride: 'Medical Letter',
      hideDocumentTypeLabel: true,
    } as UploadLookup,
    // medicalReport: {
    //   displayValue: 'Medical Report',
    //   displayCategory: 'Medical Report',
    //   documentTypes: ['Medical Report'],
    //   optOutLabel: 'I do not have a medical report and I understand that this may delay the application process',
    //   requiredErrorOverride: "The medical report is missing, upload now or check the box below."
    // } as UploadLookup,
    notWorkingReasonSpouse: {
      displayValue: 'Medical Letter',
      displayCategory: 'Reason Not Working',
      documentTypes: ['Medical Letter'],
      order: 1,
      optOutLabel:
        'My <<spouseLabel>> does not have a medical letter and I understand that not providing this document with my online application will cause delays and may result in my application being withdrawn.',
      requiredErrorOverride: 'The medical letter is missing, upload now or check the box below.',
      categoryTitleOverride: 'Medical Letter',
      hideDocumentTypeLabel: true,
    } as UploadLookup,
  },
  incomes: {
    bankAccounts: {
      displayValue: '60 day bank statements',
      documentTypes: ['Bank statement(s) showing 60 days of transactions for all your bank accounts'],
      needSupportDocument: true,
      order: 1,
      optOutLabel:
        'I do not have a bank statement and I understand that not providing this document with my online application will cause delays and may result in my application being withdrawn.',
      requiredErrorOverride:
        'The 60-day bank statement is missing. Upload now or if you do not have a bank statement check the box below.',
      categoryTitleOverride: 'Proof of all bank accounts',
    },
    bankAccountsSpouse: {
      displayValue: '60 day bank statements',
      documentTypes: ['Bank statement(s) showing 60 days of transactions for all your bank accounts'],
      needSupportDocument: true,
      order: 1,
      optOutLabel:
        'My <<spouseLabel>> does not have a bank statement and I understand that not providing this document with my online application will cause delays and may result in my application being withdrawn.',
      requiredErrorOverride:
        'The 60-day bank statement is missing. Upload now or if your <<spouseLabel>> does not have a bank statement check the box below.',
      categoryTitleOverride: 'Proof of all bank accounts',
    },
    directDepositForm: {
      displayValue: 'Direct Deposit form',
      documentTypes: [
        'Direct deposit form with a void check and signed by you.',
        'Direct deposit form stamped by the bank and signed by you.',
        'Direct deposit form from your bank that includes bank logo or stamp.',
        'Screenshot of your bank information, including your name, branch, bank and account numbers.',
      ],
      needSupportDocument: true,
      order: 2,
      optOutLabel:
        'I do not have my direct deposit information and I understand that not providing this document with my online application will cause delays and may result in my application being withdrawn.',
      requiredErrorOverride:
        'Direct deposit information is missing. Upload now or if you do not have your direct deposit information check the box below.',
      categoryTitleOverride: 'Direct Deposit form',
    },
  },
  consent: {
    consentDocument: {
      displayValue: 'Consent and Declaration',
      documentTypes: ['Consent Document'],
      categoryTitleOverride: 'Consent and Declaration',
      order: 1,
      optOutLabel:
        'I am unable to provide the consent and declaration form and I understand that not providing this document with my online application will cause delays and may result in my application being withdrawn.',
      requiredErrorOverride: 'The consent and declaration form is missing. Upload now or check the box below.',
    },
  },
};
